@import url(https://vcs.fbinhouse.se/volvo-style-guide/assets/toolkit/styles/fbi-volvo-style-guide.css);
body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  overflow-y: scroll; }
  body.disableScroll {
    overflow: hidden !important; }

#fbiPixels {
  line-height: 0;
  font-size: 0;
  height: 0;
  margin: 0;
  padding: 0; }

.zoid-outlet {
  display: none; }

#root {
  width: 100%; }

.fbi-content {
  width: 100%; }

.outerAppWrapper {
  min-height: 100vh; }

.cbvCarPage-section {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0 20px;
  padding-bottom: 100px; }
  @media screen and (max-width: 768px) {
    .cbvCarPage-section {
      position: relative;
      padding: 0 10px;
      margin-bottom: 50px; } }

.pageLoader {
  height: 1px;
  font-size: 0;
  line-height: 0; }

.cbvStartWrapper {
  width: 100%;
  height: 100%;
  margin: auto; }

.hero-section {
  background: url(../../static/media/cbv-hero-desktop.42d1e10a.jpg) no-repeat;
  background-size: cover;
  height: 50vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  background-position: 50% 20%;
  background-repeat: no-repeat;
  position: relative;
  text-align: left; }
  .hero-section:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15); }
  @media screen and (min-width: 1600px) {
    .hero-section {
      background-position: 50% 35% !important; } }
  @media screen and (max-width: 1600px) {
    .hero-section {
      height: 60vh;
      background-position: 50% 30% !important; } }
  @media screen and (min-width: 1800px) {
    .hero-section {
      height: 70vh !important; } }
  @media screen and (max-width: 768px) {
    .hero-section {
      height: 82vh;
      background-position: 50% 50% !important;
      background: url(../../static/media/cbv-hero-mobile.d5cd8017.jpg);
      background-repeat: no-repeat;
      background-size: cover; } }
  .hero-section .heroWrapper {
    width: 100%;
    max-width: 1600px;
    position: relative;
    height: 100%;
    margin: auto; }
  .hero-section .hero-section-text {
    color: #ffffff;
    position: absolute;
    left: 100px;
    top: 50%;
    margin: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media screen and (max-width: 1400px) {
      .hero-section .hero-section-text {
        left: 80px; } }
    @media screen and (max-width: 768px) {
      .hero-section .hero-section-text {
        top: 50%;
        left: 0; } }
    .hero-section .hero-section-text ul {
      margin-left: 0;
      margin-bottom: 30px;
      padding-left: 20px; }
      @media screen and (max-width: 768px) {
        .hero-section .hero-section-text ul {
          margin-bottom: 0;
          margin-top: 5px; } }
    @media screen and (max-width: 768px) {
      .hero-section .hero-section-text {
        position: relative;
        right: 0;
        height: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; } }
    .hero-section .hero-section-text h1 {
      margin-top: 5px;
      font-family: 'Volvo Novum Medium'; }
      @media screen and (max-width: 480px) {
        .hero-section .hero-section-text h1 {
          margin-top: 0;
          margin-bottom: 0px; } }
    .hero-section .hero-section-text h3 {
      margin-bottom: 0; }
    .hero-section .hero-section-text p {
      max-width: 400px; }
      @media screen and (max-width: 768px) {
        .hero-section .hero-section-text p {
          max-width: none; } }
      .hero-section .hero-section-text p strong {
        display: block;
        padding-top: 20px; }
    .hero-section .hero-section-text [data-am-button] a {
      width: 240px; }
      @media screen and (max-width: 768px) {
        .hero-section .hero-section-text [data-am-button] a {
          display: block;
          margin: auto; } }
    @media screen and (max-width: 768px) {
      .hero-section .hero-section-text [data-am-button] {
        bottom: 52px;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 20px !important; } }
    @media screen and (min-width: 767px) {
      .hero-section .hero-section-text [data-am-button] {
        margin-left: 0;
        margin-top: 20px; } }
    @media screen and (max-width: 768px) {
      .hero-section .hero-section-text .heroPriceWrapper {
        position: absolute;
        bottom: 119px;
        left: 0;
        right: 0;
        color: #ffffff;
        margin: 0 20px; } }
    .hero-section .hero-section-text .heroPriceWrapper p {
      font-family: 'Volvo Novum Regular';
      font-size: 13px;
      margin: 0; }
      .hero-section .hero-section-text .heroPriceWrapper p strong {
        padding: 0; }
    .hero-section .hero-section-text .heroPriceWrapper .heroPriceTitle {
      font-size: 15px;
      font-family: 'Volvo Novum Medium'; }
    .hero-section .hero-section-text .heroPriceWrapper .heroPriceText {
      font-size: 20px;
      font-family: 'Volvo Novum Medium'; }
  .hero-section .anchorLink {
    max-width: 140px !important;
    margin: 0;
    margin-top: 10px;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .hero-section .anchorLink {
        position: absolute;
        bottom: 10px;
        max-width: 100% !important;
        text-align: center;
        left: 0;
        right: 0; } }
    .hero-section .anchorLink span {
      margin-left: 5px; }

.startPageWrapper {
  color: #161818;
  overflow: hidden;
  padding-top: 0px; }
  .startPageWrapper .formWrapper {
    padding: 0 20px;
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 80px; }
    .startPageWrapper .formWrapper form input {
      margin: 8px 0; }
    .startPageWrapper .formWrapper form > div:first-child {
      margin-top: 20px; }
    .startPageWrapper .formWrapper .inputContactError {
      color: #bf2012; }
    .startPageWrapper .formWrapper .inputError {
      border-color: #bf2012; }
    .startPageWrapper .formWrapper .mobileBreak {
      display: none; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .formWrapper .mobileBreak {
          display: block; } }
  .startPageWrapper .startInnerWrapper {
    width: 100%;
    height: 100%;
    max-width: 1250px;
    margin: auto;
    padding: 0;
    padding-bottom: 50px;
    padding-top: 50px;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .startPageWrapper .startInnerWrapper {
        padding-top: 40px;
        padding-bottom: 50px; } }
    .startPageWrapper .startInnerWrapper .anchorLink {
      display: inline-block;
      padding-top: 20px;
      color: #007BCD;
      cursor: pointer; }
      .startPageWrapper .startInnerWrapper .anchorLink .fbi-icon-angle-down {
        padding-left: 10px; }
    .startPageWrapper .startInnerWrapper .cbvIntro {
      padding-bottom: 50px; }
      .startPageWrapper .startInnerWrapper .cbvIntro h3, .startPageWrapper .startInnerWrapper .cbvIntro h1, .startPageWrapper .startInnerWrapper .cbvIntro h2, .startPageWrapper .startInnerWrapper .cbvIntro p {
        margin: 0; }
      .startPageWrapper .startInnerWrapper .cbvIntro h1, .startPageWrapper .startInnerWrapper .cbvIntro h2 {
        font-family: 'Volvo Novum Medium'; }
      .startPageWrapper .startInnerWrapper .cbvIntro h1 {
        font-size: 44px;
        line-height: 50px; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .startInnerWrapper .cbvIntro h1 {
            font-size: 31px;
            line-height: 40px;
            padding: 0; } }
      .startPageWrapper .startInnerWrapper .cbvIntro h2 {
        font-size: 24px; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .startInnerWrapper .cbvIntro h2 {
            font-size: 20px; } }
      .startPageWrapper .startInnerWrapper .cbvIntro h3 {
        margin-bottom: 6px; }
      .startPageWrapper .startInnerWrapper .cbvIntro p {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 18px; }
    .startPageWrapper .startInnerWrapper .tableIndicator {
      position: relative; }
      .startPageWrapper .startInnerWrapper .tableIndicator:before {
        display: none; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .startInnerWrapper .tableIndicator:before {
            padding: 0 10px; } }
      .startPageWrapper .startInnerWrapper .tableIndicator.hide:before {
        opacity: 0; }
    .startPageWrapper .startInnerWrapper .tableTitle {
      padding-top: 40px;
      padding-bottom: 40px;
      position: relative; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .startInnerWrapper .tableTitle {
          padding-top: 30px;
          padding-bottom: 20px; } }
    .startPageWrapper .startInnerWrapper .dropDownThWrapper {
      display: none;
      padding: 0 !important;
      white-space: inherit !important;
      overflow: inherit !important;
      word-break: inherit !important; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .startInnerWrapper .dropDownThWrapper {
          display: block; } }
      .startPageWrapper .startInnerWrapper .dropDownThWrapper .Dropdown-root {
        margin-left: 5px;
        font-size: 12px;
        line-height: 27px; }
        .startPageWrapper .startInnerWrapper .dropDownThWrapper .Dropdown-root .Dropdown-control {
          padding: 5px !important; }
        .startPageWrapper .startInnerWrapper .dropDownThWrapper .Dropdown-root .Dropdown-arrow {
          right: 7px;
          top: 17px; }
    .startPageWrapper .startInnerWrapper .cbvTableWrapper {
      max-width: 1000px;
      margin: auto;
      padding-bottom: 0px;
      width: 100%; }
      @media screen and (max-width: 480px) {
        .startPageWrapper .startInnerWrapper .cbvTableWrapper {
          overflow-x: scroll;
          padding-bottom: 50px;
          margin-bottom: 0px;
          margin-left: -20px;
          margin-right: -20px;
          padding: 0 20px;
          width: auto; } }
      .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable {
        border-spacing: 0;
        margin: auto; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable {
            overflow: scroll;
            width: 100%;
            table-layout: fixed; } }
        @media screen and (max-width: 480px) {
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable {
            padding: 0 10px;
            font-size: 14px;
            margin-bottom: 10px; } }
        @media screen and (max-width: 768px) {
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tbody {
            overflow: scroll; } }
        .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable td {
          padding: 10px 5px;
          overflow: hidden;
          word-break: normal;
          text-align: center;
          vertical-align: middle; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable td.fbi-icon-cancel {
            color: #818181; }
        .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th {
          padding: 10px 5px;
          overflow: hidden;
          word-break: normal;
          max-width: 160px;
          font-family: 'Volvo Novum Regular'; }
          @media screen and (max-width: 768px) {
            .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th {
              padding: 10px;
              font-size: 12px;
              white-space: nowrap; } }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th.cbvth {
            width: 100px; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th:nth-child(2) {
            border-top: 1px solid #9e9e9e;
            border-left: 1px solid #9e9e9e;
            border-right: 1px solid #9e9e9e; }
          @media screen and (max-width: 768px) {
            .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th .mobileWidth {
              width: 25%; } }
          @media screen and (max-width: 768px) {
            .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable th.mobileHide {
              display: none; } }
        .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td {
          text-align: left;
          text-align: center;
          vertical-align: middle; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(n+2) {
            text-align: center; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(n+3) {
            opacity: 1;
            transition: 0.3s ease opacity; }
            .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(n+3).animating {
              opacity: 0; }
          @media screen and (max-width: 768px) {
            .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(n+4) {
              display: none; } }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(1) {
            text-align: left;
            border-bottom: 1px solid #d2d2d2;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 15%; }
            @media screen and (max-width: 768px) {
              .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(1) {
                min-width: 170px; } }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(2) {
            border-left: 1px solid #9e9e9e;
            border-right: 1px solid #9e9e9e;
            border-bottom: 0;
            border-top: 0;
            padding: 0 50px;
            width: 1%; }
            @media screen and (max-width: 768px) {
              .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(2) {
                white-space: nowrap;
                padding: 0 30px; } }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(3) {
            border-bottom: 1px solid #d2d2d2;
            width: 10%; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr td:nth-child(4) {
            border-bottom: 1px solid #d2d2d2;
            width: 10%; }
        .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr:last-child td {
          border: 0; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr:last-child td:nth-child(2) {
            border-bottom: 1px solid #9e9e9e;
            border-left: 1px solid #9e9e9e;
            border-right: 1px solid #9e9e9e; }
        .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr .fbi-icon-checkmark {
          color: #007BCD;
          font-size: 18px; }
          .startPageWrapper .startInnerWrapper .cbvTableWrapper .cbvTable tr .fbi-icon-checkmark:before {
            font-weight: 600; }
  .startPageWrapper .cbvStartBanner {
    width: 100%;
    padding-bottom: 30px;
    position: relative; }
    .startPageWrapper .cbvStartBanner .bannerImg {
      width: 100%;
      height: 740px;
      position: relative;
      background-image: url(../../static/media/cbvStartBanner.14d53f30.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (max-width: 480px) {
        .startPageWrapper .cbvStartBanner .bannerImg {
          height: 300px; } }
    .startPageWrapper .cbvStartBanner .textWrapper {
      width: 100%;
      max-width: 490px;
      position: absolute;
      left: 130px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: left;
      background: rgba(255, 255, 255, 0.5);
      padding: 20px;
      border-radius: 2px; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .cbvStartBanner .textWrapper {
          left: 0;
          background: none; } }
      @media screen and (max-width: 480px) {
        .startPageWrapper .cbvStartBanner .textWrapper {
          position: relative;
          display: block;
          -webkit-transform: none;
                  transform: none;
          padding-top: 30px;
          padding-right: 20px;
          padding-left: 20px; } }
      .startPageWrapper .cbvStartBanner .textWrapper h3 {
        margin: 0; }
      .startPageWrapper .cbvStartBanner .textWrapper h2 {
        margin-top: 10px;
        margin-bottom: 25px;
        font-size: 28px;
        max-width: 400px; }
        @media screen and (max-width: 480px) {
          .startPageWrapper .cbvStartBanner .textWrapper h2 {
            line-height: 34px; } }
      .startPageWrapper .cbvStartBanner .textWrapper a {
        font-family: 'Volvo Novum Medium';
        color: #181616; }
  .startPageWrapper .cbvInfoBottomIntro {
    text-align: center;
    display: block;
    width: 100%;
    margin: auto;
    padding-top: 0px;
    padding-bottom: 50px; }
    @media screen and (max-width: 768px) {
      .startPageWrapper .cbvInfoBottomIntro {
        padding: 0 20px;
        margin-bottom: 30px; } }
    @media screen and (min-width: 768px) {
      .startPageWrapper .cbvInfoBottomIntro {
        background-color: #ffffff;
        padding: 50px 0;
        padding-top: 0; } }
    .startPageWrapper .cbvInfoBottomIntro h2 {
      display: block;
      width: 100%;
      text-align: center;
      padding-bottom: 10px; }
    .startPageWrapper .cbvInfoBottomIntro .cbvInfoText {
      max-width: 650px; }
      @media screen and (min-width: 768px) {
        .startPageWrapper .cbvInfoBottomIntro .cbvInfoText {
          margin-bottom: 10px;
          padding-top: 30px; } }
    .startPageWrapper .cbvInfoBottomIntro .stepWrapper {
      width: 100%;
      width: 300px;
      display: flex;
      height: 100px;
      align-items: center;
      margin: auto;
      margin-bottom: 50px;
      margin-top: -20px; }
      @media screen and (min-width: 768px) {
        .startPageWrapper .cbvInfoBottomIntro .stepWrapper {
          margin-bottom: 40px;
          width: 460px; } }
      .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle {
        border-radius: 50%;
        width: 30px;
        height: 26px;
        padding: 4px;
        border: 2px solid #666;
        text-align: center;
        font-size: 12px;
        line-height: 13px; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle {
            height: 24px;
            line-height: 12px; } }
        .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle p {
          position: absolute;
          margin-left: -30px;
          text-align: center;
          margin-top: 12px;
          font-size: 15px;
          line-height: normal; }
        .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle.circle1 p {
          margin-left: -15px; }
        .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle.circle2 p {
          margin-left: -30px; }
        .startPageWrapper .cbvInfoBottomIntro .stepWrapper .circle.circle3 p {
          margin-left: -20px; }
      .startPageWrapper .cbvInfoBottomIntro .stepWrapper .line {
        width: 50%;
        border-top: 2px solid #666666; }
  .startPageWrapper .cbvInfoBottom {
    padding-top: 0px;
    padding-bottom: 30px;
    margin-bottom: 50px;
    background-color: #f6f6f6;
    padding-bottom: 60px; }
    @media screen and (max-width: 768px) {
      .startPageWrapper .cbvInfoBottom {
        padding-top: 30px;
        padding-bottom: 50px; } }
    .startPageWrapper .cbvInfoBottom h2 {
      text-align: center;
      padding-bottom: 30px; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .cbvInfoBottom h2 {
          display: block;
          text-align: center;
          padding-bottom: 30px; } }
      @media screen and (min-width: 768px) {
        .startPageWrapper .cbvInfoBottom h2.cbvInfoBottomHeader {
          padding-top: 60px; } }
    .startPageWrapper .cbvInfoBottom .cbvInfoBottomInnerWrapper {
      width: 100%;
      height: 100%;
      max-width: 1250px;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      padding: 0 15px; }
      .startPageWrapper .cbvInfoBottom .cbvInfoBottomInnerWrapper [data-am-button] {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-left: auto;
        margin-right: auto; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .cbvInfoBottom .cbvInfoBottomInnerWrapper {
          padding: 0 10px; }
          .startPageWrapper .cbvInfoBottom .cbvInfoBottomInnerWrapper [data-am-button] {
            width: 100%;
            margin-top: 40px; } }
      .startPageWrapper .cbvInfoBottom .cbvInfoBottomInnerWrapper a {
        margin-top: 50px; }
    .startPageWrapper .cbvInfoBottom .infoGroup {
      width: 33%;
      margin: 15px 0;
      text-align: left; }
      @media screen and (max-width: 900px) {
        .startPageWrapper .cbvInfoBottom .infoGroup {
          width: 50%; } }
      .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle {
        font-size: 22px;
        margin-top: 10px;
        position: relative; }
        .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle .fbi-icon-angle-down, .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle .fbi-icon-angle-up {
          display: none; }
        @media screen and (max-width: 768px) {
          .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle .fbi-icon-angle-down, .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle .fbi-icon-angle-up {
            display: inline-block;
            position: absolute;
            right: 0;
            opacity: 0.5; } }
      .startPageWrapper .cbvInfoBottom .infoGroup .groupText {
        max-width: 330px;
        text-align: left;
        margin: 0; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .cbvInfoBottom .infoGroup {
          width: 100%;
          background-color: #d6d6d6;
          text-align: left;
          padding: 0 20px;
          margin-bottom: 10px;
          margin-top: 0; }
          .startPageWrapper .cbvInfoBottom .infoGroup .groupTitle {
            font-size: 15px;
            font-family: 'Volvo Novum Medium'; }
          .startPageWrapper .cbvInfoBottom .infoGroup .groupText {
            margin: 0;
            max-height: 0;
            transition: 0.4s ease all;
            overflow: hidden; } }
      .startPageWrapper .cbvInfoBottom .infoGroup.toggled .groupText {
        margin-bottom: 0px; }
      @media screen and (max-width: 768px) {
        .startPageWrapper .cbvInfoBottom .infoGroup.toggled .groupText {
          margin-bottom: 20px; } }
  .startPageWrapper [data-am-button] {
    margin: 0 20px; }
    .startPageWrapper [data-am-button] a {
      width: 220px; }
  @media screen and (max-width: 768px) {
    .startPageWrapper .blue-button-nofade {
      width: 100% !important;
      margin: auto !important;
      padding: 0 !important; } }
  .startPageWrapper .mobileBreak {
    display: none; }
    @media screen and (max-width: 480px) {
      .startPageWrapper .mobileBreak {
        display: block;
        font-size: 0;
        line-height: 0; } }
  .startPageWrapper .cbvContacts {
    margin: auto;
    padding: 0 20px 50px 20px;
    text-align: center; }
    .startPageWrapper .cbvContacts h2 {
      padding-bottom: 10px; }
    .startPageWrapper .cbvContacts p {
      margin: 0 auto 10px; }
    .startPageWrapper .cbvContacts .links {
      padding-top: 0px; }
      .startPageWrapper .cbvContacts .links a {
        display: block;
        max-width: 300px;
        margin: auto; }
        .startPageWrapper .cbvContacts .links a:last-of-type {
          padding-top: 10px; }
    @media screen and (max-width: 768px) {
      .startPageWrapper .cbvContacts .contacts {
        padding-top: 50px; } }

.contactMeContainer {
  width: 100%;
  position: relative;
  display: block;
  padding: 10px 15px;
  padding-top: 60px;
  margin: 15px;
  margin-bottom: 45px;
  background-color: #393939;
  color: #ffffff;
  text-align: center; }
  @media screen and (max-width: 900px) {
    .contactMeContainer {
      padding-top: 30px;
      margin: 0;
      margin-bottom: 20px; } }
  .contactMeContainer .contactMeInnerWrapper {
    position: relative;
    opacity: 1;
    transition: opacity 0.4s ease; }
    .contactMeContainer .contactMeInnerWrapper.hideForm {
      opacity: 0; }
  .contactMeContainer .confirmMessage {
    display: block;
    position: absolute;
    opacity: 1;
    top: 45%;
    left: 0;
    right: 0;
    transition: opacity 0.6s ease; }
    .contactMeContainer .confirmMessage.hideMessage {
      pointer-events: none;
      opacity: 0; }
    .contactMeContainer .confirmMessage h2 {
      margin-bottom: 0; }
  .contactMeContainer .headerContainer h2 {
    display: inline-block;
    position: relative;
    margin-bottom: 3px; }
    .contactMeContainer .headerContainer h2:before {
      content: '\E876';
      font-family: 'fbi-icons';
      font-style: normal;
      font-weight: 400;
      font-size: 100px;
      display: inline-block;
      text-decoration: inherit;
      width: auto;
      margin-right: .2em;
      text-align: center;
      -webkit-font-feature-settings: normal;
              font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      position: absolute;
      bottom: -50px;
      left: -180px; }
      @media screen and (max-width: 900px) {
        .contactMeContainer .headerContainer h2:before {
          content: none;
          display: none; } }
  .contactMeContainer .headerContainer p {
    text-align: left;
    max-width: 395px; }
    @media screen and (max-width: 900px) {
      .contactMeContainer .headerContainer p {
        text-align: center; } }
  .contactMeContainer form {
    width: 100%;
    max-width: 850px;
    margin: auto;
    padding: 20px 0;
    padding-top: 50px; }
    @media screen and (max-width: 900px) {
      .contactMeContainer form {
        max-width: 550px;
        padding-top: 20px; } }
    .contactMeContainer form [data-am-input] {
      width: calc(33% - 15px);
      margin: 0;
      display: inline-block; }
      @media screen and (max-width: 900px) {
        .contactMeContainer form [data-am-input] {
          width: 100%;
          display: block;
          padding: 0; } }
      .contactMeContainer form [data-am-input]:nth-child(2) {
        margin: 0 15px; }
        @media screen and (max-width: 900px) {
          .contactMeContainer form [data-am-input]:nth-child(2) {
            margin: 15px 0; } }
      .contactMeContainer form [data-am-input] input {
        margin-bottom: 0; }
    .contactMeContainer form [data-am-textarea] {
      margin: 0 11px;
      margin-top: 20px;
      margin-bottom: 12px; }
      @media screen and (max-width: 900px) {
        .contactMeContainer form [data-am-textarea] {
          margin-left: 0;
          margin-right: 0; } }
      .contactMeContainer form [data-am-textarea] textarea {
        height: 100px; }
    .contactMeContainer form input[type="submit"] {
      max-width: 400px;
      text-align: center;
      margin: auto;
      height: 45px;
      line-height: 45px;
      margin-top: 35px; }

.cbvCarsContainer {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden; }

.cbvCarWrapper {
  position: relative;
  width: calc(50% - 30px);
  margin-bottom: 30px;
  background-color: #f5f5f5;
  padding: 50px;
  margin-right: 15px;
  margin-left: 15px;
  padding-bottom: 150px;
  padding-top: 50px;
  z-index: 0; }
  .cbvCarWrapper .cbvCarTitleWrapper {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%; }
    @media screen and (max-width: 768px) {
      .cbvCarWrapper .cbvCarTitleWrapper {
        position: relative;
        left: auto;
        left: initial;
        -webkit-transform: none;
                transform: none; } }
    .cbvCarWrapper .cbvCarTitleWrapper .carName, .cbvCarWrapper .cbvCarTitleWrapper .carTitle {
      text-align: center; }
    .cbvCarWrapper .cbvCarTitleWrapper .carName {
      max-width: 500px;
      margin: auto;
      margin-bottom: 5px; }
  @media screen and (max-width: 768px) {
    .cbvCarWrapper {
      flex-flow: column;
      margin-bottom: 20px;
      width: 100%;
      padding: 15px;
      margin-right: 0;
      margin-left: 0; }
      .cbvCarWrapper:nth-child(2n) {
        margin-right: 0;
        margin-left: 0; } }
  .cbvCarWrapper .cbvCarPriceWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px; }
    @media screen and (max-width: 1000px) {
      .cbvCarWrapper .cbvCarPriceWrapper {
        flex-flow: column; } }
    @media screen and (max-width: 768px) {
      .cbvCarWrapper .cbvCarPriceWrapper {
        flex-flow: nowrap; } }
    @media screen and (min-width: 768px) {
      .cbvCarWrapper .cbvCarPriceWrapper {
        position: absolute;
        bottom: 30px;
        left: 0;
        padding: 0 50px; } }
    .cbvCarWrapper .cbvCarPriceWrapper .cbvPrice {
      width: 50%; }
      @media screen and (max-width: 1000px) {
        .cbvCarWrapper .cbvCarPriceWrapper .cbvPrice {
          width: 100%; } }
      .cbvCarWrapper .cbvCarPriceWrapper .cbvPrice h2 {
        margin-bottom: 3px; }
      @media screen and (max-width: 480px) {
        .cbvCarWrapper .cbvCarPriceWrapper .cbvPrice p {
          font-size: 12px; } }
    .cbvCarWrapper .cbvCarPriceWrapper [data-am-button] {
      width: 50%; }
      @media screen and (max-width: 1000px) {
        .cbvCarWrapper .cbvCarPriceWrapper [data-am-button] {
          width: 100%; } }
      .cbvCarWrapper .cbvCarPriceWrapper [data-am-button] a {
        width: 100%;
        padding: 0; }
  .cbvCarWrapper .popular {
    position: absolute;
    top: 15px;
    left: -19px;
    background-color: #007BCD;
    width: 150px;
    padding: 12px 0px;
    text-align: center; }
    .cbvCarWrapper .popular:before {
      content: "";
      width: 0px;
      height: 0;
      border-top: 24px solid transparent;
      border-right: 19px solid #014877;
      border-bottom: 12px solid transparent;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
    .cbvCarWrapper .popular p {
      color: #fff;
      line-height: 0;
      margin-bottom: 0;
      font-family: 'Volvo Novum Medium';
      padding-left: 10px;
      margin-left: -15px; }

.cbvCarsContainer .sliderWrapper {
  padding-top: 60px; }

.sliderWrapper {
  display: inline-block;
  margin-left: 0;
  width: 100%;
  padding: 40px 0;
  top: 40px; }
  @media screen and (max-width: 768px) {
    .sliderWrapper {
      top: auto;
      top: initial;
      padding-top: 15px !important;
      padding-bottom: 40px !important; } }
  .sliderWrapper img {
    width: 100%; }
  .sliderWrapper .slick-slider {
    outline: none !important; }
    .sliderWrapper .slick-slider .slick-arrow {
      z-index: 99; }
      .sliderWrapper .slick-slider .slick-arrow.slick-prev {
        left: -35px; }
        .sliderWrapper .slick-slider .slick-arrow.slick-prev:before {
          content: '\E86F';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .sliderWrapper .slick-slider .slick-arrow.slick-prev {
            left: -15px; } }
      .sliderWrapper .slick-slider .slick-arrow.slick-next {
        right: -20px;
        text-align: right; }
        .sliderWrapper .slick-slider .slick-arrow.slick-next:before {
          content: '\E870';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .sliderWrapper .slick-slider .slick-arrow.slick-next {
            right: 0px; } }
    .sliderWrapper .slick-slider .slick-dots {
      bottom: -20px; }
      @media screen and (max-width: 768px) {
        .sliderWrapper .slick-slider .slick-dots {
          bottom: -15px; } }
      .sliderWrapper .slick-slider .slick-dots li {
        margin: -2px;
        width: 15px; }
        .sliderWrapper .slick-slider .slick-dots li button {
          padding: 0; }
          .sliderWrapper .slick-slider .slick-dots li button:before {
            width: 2px;
            height: 2px;
            display: inline-block;
            box-sizing: content-box;
            border: 1px solid #000;
            position: relative;
            border-radius: 50%;
            color: transparent;
            background-color: black;
            opacity: 0.4; }
          .sliderWrapper .slick-slider .slick-dots li button:hover:before {
            opacity: 1; }
        .sliderWrapper .slick-slider .slick-dots li.slick-active button:before {
          display: inline-block;
          box-sizing: content-box;
          border: 1px solid #007BCD;
          position: relative;
          border-radius: 50%;
          color: transparent;
          background-color: #007BCD;
          opacity: 1; }
    .sliderWrapper .slick-slider .slick-slide div {
      outline: none !important; }

.stepOuterWrapper {
  margin-left: -10px;
  margin-right: -10px; }
  .stepOuterWrapper .mobile-back {
    display: none;
    position: relative;
    background-color: #073653;
    height: 100%;
    width: 100%;
    padding: 22px 0; }
    .stepOuterWrapper .mobile-back .backTitle {
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      font-size: 18px;
      font-family: 'Volvo Novum Medium'; }
    @media screen and (max-width: 768px) {
      .stepOuterWrapper .mobile-back {
        display: block; }
        .stepOuterWrapper .mobile-back .iconWrapper {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #007BCD;
          width: 15%;
          height: 100%; }
          .stepOuterWrapper .mobile-back .iconWrapper .arrowBack {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 99; } }
    .stepOuterWrapper .mobile-back a {
      color: #ffffff; }
  .stepOuterWrapper .stepInnerWrapper {
    margin: 0 10px; }
    .stepOuterWrapper .stepInnerWrapper .header {
      margin: auto;
      padding-bottom: 20px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .stepOuterWrapper .stepInnerWrapper .header {
          padding: 20px 0;
          padding-top: 0px; }
          .stepOuterWrapper .stepInnerWrapper .header h3 {
            margin-bottom: 0; }
          .stepOuterWrapper .stepInnerWrapper .header h1 {
            margin-bottom: 5px; } }

.Step3Wrapper {
  position: relative;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.step3FormWrapper {
  width: 50%;
  /* Change Autocomplete styles in Chrome*/ }
  @media screen and (max-width: 768px) {
    .step3FormWrapper {
      width: 100%;
      padding-bottom: 20px; } }
  .step3FormWrapper h2 {
    text-align: center;
    padding-bottom: 20px; }
  .step3FormWrapper form {
    position: relative; }
  .step3FormWrapper .inputError {
    border: 1px solid #ff3d00 !important; }
  .step3FormWrapper .formError {
    display: block;
    text-align: center;
    font-family: 'Volvo Novum Regular';
    padding-top: 20px;
    color: #ff3d00; }
  .step3FormWrapper .seperator {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #CEE5F6; }
  .step3FormWrapper .formSectionOne {
    background-color: #E5F1FA;
    padding: 0 20px;
    padding-top: 20px;
    margin: auto; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .formSectionOne {
        padding: 0; } }
  .step3FormWrapper .formSectionTwo {
    background-color: #e5f1fa;
    padding: 20px 20px 40px 20px;
    margin: auto; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .formSectionTwo {
        padding: 20px 10px 20px 10px; } }
  .step3FormWrapper label {
    padding-bottom: 5px;
    display: block;
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: .5px;
    font-weight: 400; }
  .step3FormWrapper input[type='text'], .step3FormWrapper input[type='email'], .step3FormWrapper textarea, .step3FormWrapper select {
    height: 42px;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    font-size: 1em;
    font-family: 'Volvo Novum Light';
    margin-bottom: .75em;
    padding: 10px 8px 7px 8px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper input[type='text'], .step3FormWrapper input[type='email'], .step3FormWrapper textarea, .step3FormWrapper select {
        border: 1px solid #79B7E0; } }
  .step3FormWrapper input:-webkit-autofill,
  .step3FormWrapper input:-webkit-autofill:hover,
  .step3FormWrapper input:-webkit-autofill:focus
textarea:-webkit-autofill,
  .step3FormWrapper textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
  .step3FormWrapper select:-webkit-autofill,
  .step3FormWrapper select:-webkit-autofill:hover,
  .step3FormWrapper select:-webkit-autofill:focus {
    -webkit-text-fill-color: #161618;
    -webkit-box-shadow: 0 0 0 30px white inset !important; }
  .step3FormWrapper .formContactHeader {
    display: block;
    width: 100%;
    margin: 0;
    padding: 20px 0;
    font-size: 22px; }
  .step3FormWrapper .deadlerHeader {
    padding-top: 0; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .deadlerHeader {
        padding: 0 10px;
        padding-bottom: 20px;
        padding-top: 10px; } }
  .step3FormWrapper .formContactWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    padding-top: 20px; }
    .step3FormWrapper .formContactWrapper .contactForm {
      width: calc(50% - 10px);
      display: inline-block; }
      @media screen and (min-width: 768px) {
        .step3FormWrapper .formContactWrapper .contactForm:nth-of-type(1) {
          margin-right: 10px; } }
      @media screen and (max-width: 768px) {
        .step3FormWrapper .formContactWrapper .contactForm {
          width: 100%; } }
      .step3FormWrapper .formContactWrapper .contactForm p {
        background-color: #fbeaed;
        color: #eb3a47;
        padding: 10px;
        margin-top: -10px; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .formContactWrapper {
        border: 0;
        padding-top: 20px;
        padding-bottom: 10px; } }
  .step3FormWrapper .ssnWrapper {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 0px;
    background-color: #E5F1FA; }
    .step3FormWrapper .ssnWrapper .ssnForm {
      width: 350px;
      margin: auto; }
      .step3FormWrapper .ssnWrapper .ssnForm label {
        color: black;
        margin-bottom: 10px; }
        @media screen and (max-width: 768px) {
          .step3FormWrapper .ssnWrapper .ssnForm label {
            text-align: center; } }
        .step3FormWrapper .ssnWrapper .ssnForm label .mobileBreak {
          display: none;
          height: 0; }
          @media screen and (max-width: 768px) {
            .step3FormWrapper .ssnWrapper .ssnForm label .mobileBreak {
              display: block; } }
      @media screen and (max-width: 768px) {
        .step3FormWrapper .ssnWrapper .ssnForm {
          width: 100%; } }
      .step3FormWrapper .ssnWrapper .ssnForm .ssnError {
        text-align: center;
        color: #ee5661;
        position: relative;
        top: 10px; }
      @media screen and (max-width: 768px) {
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper {
          display: flex; }
          .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper .ssnLabel {
            display: none; } }
      .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input {
        text-align: center;
        margin-bottom: 10px;
        padding: 0;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
        @media screen and (max-width: 768px) {
          .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input {
            margin-bottom: 0;
            margin-right: 5px;
            max-width: 100%; } }
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::-webkit-input-placeholder {
          text-align: center; }
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::-ms-input-placeholder {
          text-align: center; }
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::placeholder {
          text-align: center; }
          @media screen and (max-width: 480px) {
            .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::-webkit-input-placeholder {
              font-size: 12px; }
            .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::-ms-input-placeholder {
              font-size: 12px; }
            .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper input::placeholder {
              font-size: 12px; } }
      .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper [data-am-button] {
        position: relative;
        display: inline-block;
        background-color: #007bcd;
        color: #fff !important;
        border: 1px solid #007bcd;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        height: 42px;
        line-height: 42px !important;
        box-sizing: content-box;
        font-size: 15px;
        border: 0;
        padding: 0;
        width: 100%;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper [data-am-button].loading {
          background-color: transparent;
          pointer-events: none; }
        .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper [data-am-button] .blue-button-nofade.loading {
          visibility: hidden;
          pointer-events: none; }
        @media screen and (max-width: 768px) {
          .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper [data-am-button] {
            width: 70px; } }
      .step3FormWrapper .ssnWrapper .ssnForm .inputWrapper svg {
        position: absolute;
        top: 7px;
        left: 0;
        right: 0;
        margin: auto; }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .ssnWrapper {
        border: 0;
        padding: 20px 10px; } }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .ssnWrapper .formContactHeader {
        color: #B4B4B4; } }
  .step3FormWrapper .personFormWrapper .personform {
    margin-bottom: 5px; }
    .step3FormWrapper .personFormWrapper .personform p {
      background-color: #fbeaed;
      color: #eb3a47;
      padding: 10px;
      margin-top: -10px; }
  .step3FormWrapper .personFormWrapper .positionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px; }
    .step3FormWrapper .personFormWrapper .positionWrapper .personform {
      display: inline-block; }
      .step3FormWrapper .personFormWrapper .positionWrapper .personform:nth-of-type(1) {
        margin-right: 10px;
        width: 30%; }
      .step3FormWrapper .personFormWrapper .positionWrapper .personform:nth-of-type(2) {
        width: calc(70% - 10px); }
      @media screen and (max-width: 768px) {
        .step3FormWrapper .personFormWrapper .positionWrapper .personform {
          display: block; }
          .step3FormWrapper .personFormWrapper .positionWrapper .personform:nth-of-type(1) {
            margin-right: 10px;
            width: 50%; }
          .step3FormWrapper .personFormWrapper .positionWrapper .personform:nth-of-type(2) {
            width: 100%; } }
    @media screen and (max-width: 768px) {
      .step3FormWrapper .personFormWrapper .positionWrapper #custommerPosition {
        margin-bottom: 0; } }
  @media screen and (min-width: 768px) {
    .step3FormWrapper .formErrorText {
      display: none; } }

.retailersWrapper {
  position: relative;
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .retailersWrapper {
      padding: 0;
      margin-top: 20px; } }
  .retailersWrapper span {
    position: absolute;
    right: 10px;
    top: 35px; }
  .retailersWrapper .dealerSelect {
    margin: 0; }
  .retailersWrapper p {
    background-color: #fbeaed;
    color: #eb3a47;
    padding: 10px; }

.includedPopup {
  position: fixed;
  right: 0;
  max-width: 500px;
  z-index: 200;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.55);
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%); }
  .includedPopup h2 {
    font-size: 20px;
    font-family: 'Volvo Novum Medium';
    margin-bottom: 0; }
  .includedPopup .innerWrapper {
    overflow-y: scroll;
    height: 60%;
    max-height: 600px;
    padding: 20px; }
  @media screen and (max-width: 768px) {
    .includedPopup {
      width: 96%; } }

.close {
  width: 21px;
  height: 21px;
  top: 2px;
  right: 10px !important;
  position: absolute;
  margin: auto;
  padding: 20px;
  z-index: 999;
  opacity: 0.4;
  cursor: pointer; }
  @media screen and (min-width: 786px) {
    .close {
      right: 0px !important; } }

.close:before, .close:after {
  position: absolute;
  left: 20px;
  content: ' ';
  height: 25px;
  width: 2px;
  background-color: #545454;
  top: 8px; }

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.SummarySliderWrapper {
  display: inline-block;
  margin-left: 0;
  width: 100%;
  padding: 0; }
  @media screen and (max-width: 768px) {
    .SummarySliderWrapper {
      padding-bottom: 0;
      padding-top: 0;
      margin-left: 0; } }
  @media screen and (max-width: 768px) {
    .SummarySliderWrapper .sliderWrapper {
      padding-left: 0px;
      padding-right: 0px; } }
  .SummarySliderWrapper img {
    width: 100%; }
    @media screen and (max-width: 768px) {
      .SummarySliderWrapper img {
        width: 90%;
        margin: auto; } }
  .SummarySliderWrapper .slick-slider {
    outline: none !important; }
    .SummarySliderWrapper .slick-slider .slick-arrow {
      z-index: 99; }
      .SummarySliderWrapper .slick-slider .slick-arrow.slick-prev {
        left: -35px; }
        .SummarySliderWrapper .slick-slider .slick-arrow.slick-prev:before {
          content: '\E86F';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .SummarySliderWrapper .slick-slider .slick-arrow.slick-prev {
            left: -5px; } }
        @media screen and (max-width: 480px) {
          .SummarySliderWrapper .slick-slider .slick-arrow.slick-prev {
            left: 0px !important; } }
      .SummarySliderWrapper .slick-slider .slick-arrow.slick-next {
        right: -20px;
        text-align: right; }
        .SummarySliderWrapper .slick-slider .slick-arrow.slick-next:before {
          content: '\E870';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .SummarySliderWrapper .slick-slider .slick-arrow.slick-next {
            right: 5px; } }
        @media screen and (max-width: 480px) {
          .SummarySliderWrapper .slick-slider .slick-arrow.slick-next {
            right: 0px !important; } }
    .SummarySliderWrapper .slick-slider .slick-dots {
      bottom: -20px; }
      @media screen and (max-width: 768px) {
        .SummarySliderWrapper .slick-slider .slick-dots {
          bottom: -15px; } }
      .SummarySliderWrapper .slick-slider .slick-dots li {
        margin: -2px;
        width: 15px; }
        .SummarySliderWrapper .slick-slider .slick-dots li button {
          padding: 0; }
          .SummarySliderWrapper .slick-slider .slick-dots li button:before {
            width: 2px;
            height: 2px;
            display: inline-block;
            box-sizing: content-box;
            border: 1px solid #000;
            position: relative;
            border-radius: 50%;
            color: transparent;
            background-color: black;
            opacity: 0.4; }
          .SummarySliderWrapper .slick-slider .slick-dots li button:hover:before {
            opacity: 1; }
        .SummarySliderWrapper .slick-slider .slick-dots li.slick-active button:before {
          display: inline-block;
          box-sizing: content-box;
          border: 1px solid #007BCD;
          position: relative;
          border-radius: 50%;
          color: transparent;
          background-color: #007BCD;
          opacity: 1; }
    .SummarySliderWrapper .slick-slider .slick-slide div {
      outline: none !important; }

.carSummaryWrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(50% - 40px);
  order: 2;
  justify-content: flex-end;
  padding-right: 50px; }
  @media screen and (max-width: 768px) {
    .carSummaryWrapper {
      width: 100%;
      order: 2;
      padding-bottom: 40px;
      padding-right: 0px; } }
  .carSummaryWrapper .carWrapper {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .carWrapper {
        width: 100%; } }
  .carSummaryWrapper .colorsWrapper {
    padding-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .colorsWrapper {
        padding-bottom: 20px; } }
    .carSummaryWrapper .colorsWrapper .colorsTitle {
      margin-bottom: 15px; }
    .carSummaryWrapper .colorsWrapper .colorContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .carSummaryWrapper .colorsWrapper .colorContainer .selectedColorWrapper .color {
        display: inline-block;
        vertical-align: middle;
        margin: 0 3px;
        width: 30px;
        height: 30px; }
      .carSummaryWrapper .colorsWrapper .colorContainer .selectedColorWrapper .selectedColor {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-left: 10px; }
      .carSummaryWrapper .colorsWrapper .colorContainer .colorPrice {
        margin: 0; }
  .carSummaryWrapper .poseWrapper {
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .poseWrapper {
        padding: 0 10px; } }
  .carSummaryWrapper .optionsWrapper {
    width: 100%;
    max-width: 400px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .optionsWrapper {
        width: 100%;
        max-width: none; } }
    .carSummaryWrapper .optionsWrapper .optionsTitle {
      font-size: 18px; }
  .carSummaryWrapper .priceWrapper {
    border-top: 2px solid #007BCD;
    padding-top: 20px;
    margin-top: 30px;
    text-align: center; }
    .carSummaryWrapper .priceWrapper .cbvPrice {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px; }
      @media screen and (max-width: 768px) {
        .carSummaryWrapper .priceWrapper .cbvPrice .optionsTitle {
          display: none; }
        .carSummaryWrapper .priceWrapper .cbvPrice p {
          margin: 0; } }
      .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper {
        text-align: left; }
        .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper .totalPrice {
          position: relative;
          font-size: 21px !important;
          margin-bottom: 2px; }
          @media screen and (max-width: 768px) {
            .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper .totalPrice {
              margin-bottom: 3px !important; } }
        .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper .totalSub {
          font-size: 12px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .priceWrapper [data-am-button] {
        height: 52px !important; }
        .carSummaryWrapper .priceWrapper [data-am-button] .blue-button-nofade {
          height: 52px !important;
          font-size: 13px; } }
    .carSummaryWrapper .priceWrapper [data-am-button]:hover p:after {
      -webkit-transform: translateX(4px);
              transform: translateX(4px); }
    .carSummaryWrapper .priceWrapper [data-am-button] p:after {
      content: '\E870';
      transition: -webkit-transform ease .4s;
      transition: transform ease .4s;
      transition: transform ease .4s, -webkit-transform ease .4s;
      font-family: fbi-icons;
      font-style: normal;
      font-weight: 400;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: auto;
      margin-right: .2em;
      text-align: center;
      -webkit-font-feature-settings: normal;
              font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: 10px; }
  @media screen and (max-width: 786px) {
    .carSummaryWrapper .formErrorText {
      display: none; } }
  .carSummaryWrapper .tillValWrapper .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .carSummaryWrapper .tillValWrapper .container .optionWrapper {
      cursor: pointer; }
      .carSummaryWrapper .tillValWrapper .container .optionWrapper span.fbi-icon-cancel {
        display: inline-block;
        font-size: 10px;
        color: #007BCD;
        margin-right: 5px;
        margin-bottom: 5px;
        vertical-align: middle; }
      .carSummaryWrapper .tillValWrapper .container .optionWrapper .option {
        display: inline-block;
        color: #007BCD;
        margin-bottom: 5px;
        vertical-align: middle; }
    .carSummaryWrapper .tillValWrapper .container .optionPrice {
      margin: 0; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .tillValWrapper .container {
        margin-bottom: 5px; } }
  .carSummaryWrapper .speech-bubble {
    margin-top: 30px;
    background: #E5F1FA;
    padding: 20px;
    text-align: center;
    color: #183080;
    position: relative;
    border-radius: 15px; }
    .carSummaryWrapper .speech-bubble:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #E5F1FA;
      border-top: 0;
      border-left: 0;
      margin-left: 0px;
      margin-top: -10px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg); }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .speech-bubble {
        display: none; } }
  .carSummaryWrapper .textWrapper {
    text-align: center;
    position: relative; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper {
        margin-left: -10px;
        margin-right: -10px;
        padding: 10px; } }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper .subtitle {
        margin: 0; } }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper h2 {
        font-family: 'Volvo Novum Medium';
        font-size: 18px;
        margin-bottom: 5px; } }
    .carSummaryWrapper .textWrapper .mobile-back {
      display: none;
      background-color: #007BCD;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 15%; }
      @media screen and (max-width: 768px) {
        .carSummaryWrapper .textWrapper .mobile-back {
          display: inline-block; }
          .carSummaryWrapper .textWrapper .mobile-back .arrowBack {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 99; } }
      .carSummaryWrapper .textWrapper .mobile-back a {
        color: #ffffff; }
    .carSummaryWrapper .textWrapper a {
      color: #ffffff; }
  .carSummaryWrapper .lightboxOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199; }
  .carSummaryWrapper .cbvIncludedInfo {
    font-size: 12px;
    top: -10px;
    right: -30px;
    position: absolute;
    color: #007BCD;
    cursor: pointer;
    padding: 10px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .cbvIncludedInfo {
        color: #ffffff;
        top: inherit;
        right: inherit;
        padding: 0; } }

.carSummaryWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin: auto;
  order: 2;
  justify-content: flex-end;
  padding-right: 50px; }
  @media screen and (max-width: 768px) {
    .carSummaryWrapper {
      width: 100%;
      padding-bottom: 40px;
      padding-right: 0px; } }
  .carSummaryWrapper .carWrapper {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .carWrapper {
        width: 100%; } }
  .carSummaryWrapper .colorsWrapper {
    padding-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .colorsWrapper {
        padding-bottom: 20px; } }
    .carSummaryWrapper .colorsWrapper .colorsTitle {
      margin-bottom: 15px; }
    .carSummaryWrapper .colorsWrapper .colorContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .carSummaryWrapper .colorsWrapper .colorContainer .selectedColorWrapper .color {
        display: inline-block;
        vertical-align: middle;
        margin: 0 3px;
        width: 30px;
        height: 30px; }
      .carSummaryWrapper .colorsWrapper .colorContainer .selectedColorWrapper .selectedColor {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-left: 10px; }
      .carSummaryWrapper .colorsWrapper .colorContainer .colorPrice {
        margin: 0; }
  .carSummaryWrapper .poseWrapper {
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .poseWrapper {
        padding: 0 10px; } }
  .carSummaryWrapper .optionsOrderWrapper {
    width: 100%;
    max-width: 400px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .optionsOrderWrapper {
        width: 100%;
        max-width: none; } }
    .carSummaryWrapper .optionsOrderWrapper .optionsTitle {
      font-size: 18px;
      margin-left: 0px; }
  .carSummaryWrapper .priceWrapper {
    border-top: 2px solid #007BCD;
    padding-top: 20px;
    margin-top: 30px;
    text-align: center; }
    .carSummaryWrapper .priceWrapper .cbvPrice {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px; }
      .carSummaryWrapper .priceWrapper .cbvPrice h2 {
        cursor: pointer; }
      @media screen and (max-width: 768px) {
        .carSummaryWrapper .priceWrapper .cbvPrice .optionsTitle {
          display: none; } }
      .carSummaryWrapper .priceWrapper .cbvPrice p {
        margin: 0; }
      .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper {
        text-align: left; }
        .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper .totalPrice {
          font-size: 22px;
          margin-bottom: 2px; }
        .carSummaryWrapper .priceWrapper .cbvPrice .totalPriceWrapper .totalSub {
          font-size: 12px; }
    .carSummaryWrapper .priceWrapper [data-am-button] {
      position: relative;
      display: inline-block;
      background-color: #007bcd;
      color: #fff !important;
      border: 1px solid #007bcd;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      height: 55px;
      line-height: 55px !important;
      box-sizing: content-box;
      font-size: 15px;
      border: 0;
      padding: 0;
      width: 100%;
      cursor: pointer; }
      .carSummaryWrapper .priceWrapper [data-am-button] span {
        transition: color .3s ease,-webkit-transform .3s ease;
        transition: transform .3s ease,color .3s ease;
        transition: transform .3s ease,color .3s ease,-webkit-transform .3s ease;
        position: absolute;
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      .carSummaryWrapper .priceWrapper [data-am-button]:hover span {
        -webkit-transform: translateX(8px);
                transform: translateX(8px); }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .priceWrapper {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        background-color: #073653;
        padding: 10px;
        color: #fff;
        border: 0;
        margin-top: 0;
        z-index: 99; }
        .carSummaryWrapper .priceWrapper .cbvPrice {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          margin-bottom: 0;
          text-align: left; }
          .carSummaryWrapper .priceWrapper .cbvPrice .totalPrice {
            margin-bottom: 2px; }
        .carSummaryWrapper .priceWrapper [data-am-button] {
          width: 50%; }
          .carSummaryWrapper .priceWrapper [data-am-button] a {
            height: 50px;
            line-height: 50px !important; } }
  .carSummaryWrapper .tillValWrapper .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .carSummaryWrapper .tillValWrapper .container .optionWrapper {
      cursor: pointer; }
      .carSummaryWrapper .tillValWrapper .container .optionWrapper span.fbi-icon-cancel {
        display: inline-block;
        font-size: 10px;
        color: #007BCD;
        margin-right: 5px;
        margin-bottom: 5px;
        vertical-align: middle; }
      .carSummaryWrapper .tillValWrapper .container .optionWrapper .option {
        display: inline-block;
        color: #007BCD;
        margin-bottom: 5px;
        vertical-align: middle; }
    .carSummaryWrapper .tillValWrapper .container .optionPrice {
      margin: 0; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .tillValWrapper .container {
        margin-bottom: 5px; } }
  .carSummaryWrapper .speech-bubble {
    margin-top: 30px;
    background: #E5F1FA;
    padding: 20px;
    text-align: center;
    color: #183080;
    position: relative;
    border-radius: 15px; }
    .carSummaryWrapper .speech-bubble:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #E5F1FA;
      border-top: 0;
      border-left: 0;
      margin-left: 0px;
      margin-top: -10px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg); }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .speech-bubble {
        display: none; } }
  .carSummaryWrapper .textWrapper {
    text-align: center;
    position: relative; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper {
        margin-left: -10px;
        margin-right: -10px;
        padding: 10px; } }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper .subtitle {
        margin: 0; } }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .textWrapper h2 {
        font-family: 'Volvo Novum Medium';
        font-size: 18px;
        margin-bottom: 5px; } }
    .carSummaryWrapper .textWrapper .mobile-back {
      display: none;
      background-color: #007BCD;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 15%; }
      @media screen and (max-width: 768px) {
        .carSummaryWrapper .textWrapper .mobile-back {
          display: inline-block; }
          .carSummaryWrapper .textWrapper .mobile-back .arrowBack {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 99; } }
      .carSummaryWrapper .textWrapper .mobile-back a {
        color: #ffffff; }
    .carSummaryWrapper .textWrapper a {
      color: #ffffff; }
  .carSummaryWrapper .orderComplete {
    top: 200px;
    width: 100vw;
    height: 100%;
    padding-top: 0px;
    background: white;
    z-index: 0;
    text-align: center;
    margin-right: -50px; }
    .carSummaryWrapper .orderComplete p {
      max-width: 400px; }
    @media screen and (max-width: 768px) {
      .carSummaryWrapper .orderComplete {
        top: 0;
        margin: 0;
        min-width: 100vw;
        -webkit-transform: none;
                transform: none;
        left: 0;
        padding-top: 0px;
        margin-right: -10px; } }
    .carSummaryWrapper .orderComplete .orderWrapper {
      padding-top: 10px;
      margin: auto;
      max-width: none;
      margin-bottom: 10px; }
      .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper {
        max-width: 500px;
        margin: auto;
        padding: 40px;
        padding-bottom: 10px;
        background-color: #f5f5f5; }
        @media screen and (max-width: 768px) {
          .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper {
            padding: 20px;
            padding-top: 40px; } }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .textWrapper h2 {
          font-size: 22px;
          line-height: 28px; }
        @media screen and (max-width: 768px) {
          .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .textWrapper {
            background-color: transparent;
            color: #161618;
            margin: 0;
            padding: 0; }
            .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .textWrapper h2, .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .textWrapper p {
              margin: 0;
              white-space: normal;
              max-width: none;
              text-align: center; }
            .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .textWrapper h2 {
              font-size: 20px;
              font-family: 'Volvo Novum Light';
              padding-bottom: 10px; } }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .orderImg {
          width: 100%;
          max-width: 400px; }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .sliderWrapper {
          padding-bottom: 20px;
          padding-top: 10px; }
          @media screen and (max-width: 768px) {
            .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .sliderWrapper {
              padding: 10px 0 !important; } }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .optionsTitle {
          text-align: left; }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .optionWrapper .option {
          color: #161618; }
        .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .subtitle {
          margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .carSummaryWrapper .orderComplete .orderWrapper .innerWrapper .priceWrapper {
            border-top: 1px solid #161618;
            margin-top: 10px;
            background-color: transparent;
            color: #161618;
            position: relative; } }

.sliderWrapper {
  display: inline-block;
  margin-left: 0;
  width: 100%;
  padding: 40px 0;
  position: relative;
  z-index: 0; }
  @media screen and (max-width: 768px) {
    .sliderWrapper {
      padding-bottom: 20px !important;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px !important; } }
  @media screen and (max-width: 480px) {
    .sliderWrapper.step2CarPose .slick-next {
      right: 0px !important; } }
  @media screen and (max-width: 480px) {
    .sliderWrapper.step2CarPose .slick-prev {
      left: 0px !important; } }
  .sliderWrapper img {
    width: 100%; }
    @media screen and (max-width: 768px) {
      .sliderWrapper img {
        width: 90%;
        margin: auto; } }
  .sliderWrapper .slick-slider {
    outline: none !important; }
    .sliderWrapper .slick-slider .slick-arrow {
      z-index: 99; }
      .sliderWrapper .slick-slider .slick-arrow.slick-prev {
        left: -20px; }
        .sliderWrapper .slick-slider .slick-arrow.slick-prev:before {
          content: '\E86F';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .sliderWrapper .slick-slider .slick-arrow.slick-prev {
            left: -5px; } }
      .sliderWrapper .slick-slider .slick-arrow.slick-next {
        right: -20px;
        text-align: right; }
        .sliderWrapper .slick-slider .slick-arrow.slick-next:before {
          content: '\E870';
          font-family: fbi-icons;
          font-size: 24px;
          opacity: 0.4;
          color: #000; }
        @media screen and (max-width: 768px) {
          .sliderWrapper .slick-slider .slick-arrow.slick-next {
            right: -5px; } }
    .sliderWrapper .slick-slider .slick-dots {
      bottom: -25px; }
      @media screen and (max-width: 768px) {
        .sliderWrapper .slick-slider .slick-dots {
          bottom: -20px; } }
      .sliderWrapper .slick-slider .slick-dots li {
        margin: -2px;
        width: 15px; }
        .sliderWrapper .slick-slider .slick-dots li button {
          padding: 0; }
          .sliderWrapper .slick-slider .slick-dots li button:before {
            width: 2px;
            height: 2px;
            display: inline-block;
            box-sizing: content-box;
            border: 1px solid #000;
            position: relative;
            border-radius: 50%;
            color: transparent;
            background-color: black;
            opacity: 0.4; }
          .sliderWrapper .slick-slider .slick-dots li button:hover:before {
            opacity: 1; }
        .sliderWrapper .slick-slider .slick-dots li.slick-active button:before {
          display: inline-block;
          box-sizing: content-box;
          border: 1px solid #007BCD;
          position: relative;
          border-radius: 50%;
          color: transparent;
          background-color: #007BCD;
          opacity: 1; }
    .sliderWrapper .slick-slider .slick-slide div {
      outline: none !important; }

.equipmentListWrapper {
  padding-top: 50px;
  width: 100%;
  max-width: 1200px; }
  @media screen and (max-width: 768px) {
    .equipmentListWrapper {
      padding-bottom: 20px;
      padding-top: 30px; } }
  .equipmentListWrapper h2 {
    text-align: center;
    padding-bottom: 50px; }
    @media screen and (max-width: 768px) {
      .equipmentListWrapper h2 {
        padding-bottom: 10px; } }
  .equipmentListWrapper .equipmentList {
    display: block;
    width: 100%;
    position: relative; }
    .equipmentListWrapper .equipmentList .listWrapper {
      -webkit-column-count: 3;
              column-count: 3;
      -webkit-column-rule: 1px solid lightgrey;
              column-rule: 1px solid lightgrey;
      grid-column-gap: 150px;
      -webkit-column-gap: 150px;
              column-gap: 150px;
      padding: 0 20px; }
      @media screen and (max-width: 768px) {
        .equipmentListWrapper .equipmentList .listWrapper {
          padding-left: 30px;
          -webkit-column-count: 1;
                  column-count: 1; } }
      .equipmentListWrapper .equipmentList .listWrapper p.additional {
        color: #007BCD;
        text-decoration: underline;
        cursor: pointer; }
      .equipmentListWrapper .equipmentList .listWrapper p:before {
        content: "";
        border: 2px #000 solid !important;
        border-radius: 50%;
        margin-top: 10px;
        margin-left: -10px;
        position: absolute; }
  .equipmentListWrapper .featureBoxWrapper {
    position: fixed;
    top: 40%;
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    max-height: 340px;
    z-index: 99;
    padding: 10px 20px 20px;
    width: 100%;
    max-width: 400px; }
    @media screen and (max-width: 480px) {
      .equipmentListWrapper .featureBoxWrapper {
        position: fixed;
        top: 20%;
        left: 15px;
        right: 15px;
        z-index: 99;
        width: auto; } }
    .equipmentListWrapper .featureBoxWrapper .featureTextBox {
      overflow-y: auto;
      position: absolute;
      background-color: #f5f5f5;
      width: 100%;
      height: 350px;
      left: 0;
      top: 0;
      padding: 20px;
      box-shadow: 0px 0px 11px 1px rgba(161, 161, 161, 0.58); }
      .equipmentListWrapper .featureBoxWrapper .featureTextBox .featureTextHeader {
        font-family: 'Volvo Novum Medium';
        margin-top: 0px; }
      .equipmentListWrapper .featureBoxWrapper .featureTextBox p:before {
        border: 0 !important; }
    .equipmentListWrapper .featureBoxWrapper:after {
      display: none;
      background-color: #f5f5f5;
      box-shadow: -1px 1px 2px 0 rgba(161, 161, 161, 0.25);
      content: "\A0";
      height: 15px;
      left: -10px;
      position: relative;
      top: 20px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 15px;
      top: -7px;
      left: -27px; }
    .equipmentListWrapper .featureBoxWrapper .close {
      width: 21px;
      height: 21px;
      top: 0;
      right: 0;
      position: absolute;
      margin: auto;
      z-index: 999;
      top: 0;
      opacity: 0.4;
      cursor: pointer; }
    .equipmentListWrapper .featureBoxWrapper .close:before, .equipmentListWrapper .featureBoxWrapper .close:after {
      position: absolute;
      right: 0;
      content: ' ';
      height: 21px;
      width: 2px;
      background-color: #545454; }
    .equipmentListWrapper .featureBoxWrapper .close:before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .equipmentListWrapper .featureBoxWrapper .close:after {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .equipmentListWrapper .lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 10; }

.step2Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-x: hidden;
  /* -- Popup -- */ }
  @media screen and (max-width: 768px) {
    .step2Wrapper {
      margin-left: -10px;
      margin-right: -10px; } }
  .step2Wrapper .carWrapper {
    width: 50%;
    max-width: 800px; }
    @media screen and (max-width: 768px) {
      .step2Wrapper .carWrapper {
        width: 100%; } }
    .step2Wrapper .carWrapper .textWrapper {
      text-align: center;
      position: relative; }
      @media screen and (max-width: 768px) {
        .step2Wrapper .carWrapper .textWrapper {
          text-align: center;
          padding: 10px;
          padding-bottom: 0; } }
      @media screen and (max-width: 768px) {
        .step2Wrapper .carWrapper .textWrapper .subtitle {
          margin: 0; } }
      @media screen and (max-width: 768px) {
        .step2Wrapper .carWrapper .textWrapper h2 {
          font-family: 'Volvo Novum Medium';
          font-size: 18px;
          margin: auto;
          margin-bottom: 5px; } }
      .step2Wrapper .carWrapper .textWrapper .mobile-back {
        display: none;
        background-color: #007BCD;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 15%; }
        @media screen and (max-width: 768px) {
          .step2Wrapper .carWrapper .textWrapper .mobile-back {
            display: inline-block; }
            .step2Wrapper .carWrapper .textWrapper .mobile-back .arrowBack {
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              z-index: 99; } }
        .step2Wrapper .carWrapper .textWrapper .mobile-back a {
          color: #ffffff; }
      .step2Wrapper .carWrapper .textWrapper a {
        color: #ffffff; }
  .step2Wrapper .extraInfoPopup {
    opacity: 0;
    position: fixed;
    right: 0;
    max-width: 500px;
    z-index: 200;
    min-height: 250px;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.55);
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%); }
    @media screen and (max-width: 480px) {
      .step2Wrapper .extraInfoPopup {
        top: 50%;
        left: 15px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right: 15px;
        max-height: 460px;
        overflow: scroll; } }
    .step2Wrapper .extraInfoPopup p span {
      white-space: pre-line; }
  .step2Wrapper .speechBubbleModal {
    width: 100%;
    position: absolute;
    display: block;
    overflow: hidden;
    height: 100%; }
    @media screen and (max-width: 768px) {
      .step2Wrapper .speechBubbleModal {
        position: relative; } }
  .step2Wrapper .speech-bubble {
    width: 100%;
    position: absolute;
    margin-top: 30px;
    background: #E5F1FA;
    padding: 20px;
    text-align: center;
    color: #183080;
    border-radius: 15px; }
    .step2Wrapper .speech-bubble:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #E5F1FA;
      border-top: 0;
      border-left: 0;
      margin-left: 0px;
      margin-top: -10px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg); }
    @media screen and (max-width: 768px) {
      .step2Wrapper .speech-bubble {
        position: relative; } }
  .step2Wrapper .TextModal2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  @media screen and (max-width: 768px) {
    .step2Wrapper .TextModal1, .step2Wrapper .TextModal2 {
      font-size: 13px; } }
  .step2Wrapper [data-am-button] .blue-button-nofade {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: #007bcd;
    color: #fff !important;
    border: 1px solid #007bcd;
    transition: background-color .3s ease,color .3s ease;
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 55px;
    line-height: 55px !important;
    box-sizing: content-box;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .step2Wrapper [data-am-button] .blue-button-nofade {
        height: 52px !important;
        line-height: 52px !important; } }
    .step2Wrapper [data-am-button] .blue-button-nofade:hover p:after {
      -webkit-transform: translateX(4px);
              transform: translateX(4px); }
    .step2Wrapper [data-am-button] .blue-button-nofade p:after {
      content: '\E870';
      transition: -webkit-transform ease .4s;
      transition: transform ease .4s;
      transition: transform ease .4s, -webkit-transform ease .4s;
      font-family: fbi-icons;
      font-style: normal;
      font-weight: 400;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: auto;
      margin-right: .2em;
      text-align: center;
      -webkit-font-feature-settings: normal;
              font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: 10px; }
  .step2Wrapper .techData {
    max-width: 500px;
    padding-bottom: 100px;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .step2Wrapper .techData {
        padding-bottom: 40px;
        padding-top: 40px; } }
  .step2Wrapper .colorsWrapper {
    padding-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .step2Wrapper .colorsWrapper {
        padding-bottom: 10px; } }
    .step2Wrapper .colorsWrapper .colorsTitle {
      margin-bottom: 15px;
      font-size: 18px; }
    .step2Wrapper .colorsWrapper .colorWrapper, .step2Wrapper .colorsWrapper .color {
      display: inline-block;
      margin: 0 3px;
      width: 30px;
      height: 30px;
      cursor: pointer; }
      .step2Wrapper .colorsWrapper .colorWrapper:first-of-type, .step2Wrapper .colorsWrapper .color:first-of-type {
        margin-left: 0px; }
      .step2Wrapper .colorsWrapper .colorWrapper.selected, .step2Wrapper .colorsWrapper .color.selected {
        border: 2px solid #007BCD; }
      .step2Wrapper .colorsWrapper .colorWrapper img[data-selected="true"], .step2Wrapper .colorsWrapper .color img[data-selected="true"] {
        border: 2px solid #007BCD; }
      .step2Wrapper .colorsWrapper .colorWrapper img#selected, .step2Wrapper .colorsWrapper .color img#selected {
        border: 2px solid #007BCD; }
      .step2Wrapper .colorsWrapper .colorWrapper img.selected, .step2Wrapper .colorsWrapper .color img.selected {
        border: 2px solid #007BCD; }
    .step2Wrapper .colorsWrapper img {
      width: 30px;
      height: 30px; }
      .step2Wrapper .colorsWrapper img:hover {
        border: 2px solid #007BCD; }
  .step2Wrapper .poseWrapper {
    overflow: hidden; }
  .step2Wrapper .optionsWrapper {
    position: relative;
    width: 50%;
    max-width: 320px; }
    @media screen and (max-width: 768px) {
      .step2Wrapper .optionsWrapper {
        width: 100%;
        max-width: none;
        padding: 0 20px; } }
    .step2Wrapper .optionsWrapper .extraTillval {
      font-style: italic;
      text-align: center;
      margin-top: 20px;
      color: #007bcd;
      cursor: pointer; }
  .step2Wrapper .priceWrapper {
    border-top: 2px solid #CCCCCC;
    padding-top: 20px;
    margin-top: 30px;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .step2Wrapper .priceWrapper {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        background-color: #073653;
        padding: 10px;
        color: #fff;
        border: 0;
        margin-top: 0;
        z-index: 10; }
        .step2Wrapper .priceWrapper .cbvPrice {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          margin-bottom: 0;
          text-align: left; } }
    @media screen and (max-width: 768px) and (max-width: 768px) {
      .step2Wrapper .priceWrapper .cbvPrice {
        flex-flow: column; }
        .step2Wrapper .priceWrapper .cbvPrice p {
          font-size: 12px; } }
    @media screen and (max-width: 768px) {
          .step2Wrapper .priceWrapper .cbvPrice h2 {
            margin-bottom: 2px; } }
      @media screen and (max-width: 768px) and (max-width: 768px) {
        .step2Wrapper .priceWrapper .cbvPrice h2 {
          margin-bottom: 4px; } }
    @media screen and (max-width: 768px) {
        .step2Wrapper .priceWrapper [data-am-button] {
          width: 50%; }
          .step2Wrapper .priceWrapper [data-am-button] a {
            height: 50px;
            line-height: 50px !important; } }
    @media screen and (max-width: 768px) and (max-width: 768px) {
      .step2Wrapper .priceWrapper [data-am-button] {
        height: 52px;
        line-height: 52px; } }
    .step2Wrapper .priceWrapper .cbvPrice {
      margin-bottom: 20px; }
      .step2Wrapper .priceWrapper .cbvPrice p {
        margin: 0; }
      .step2Wrapper .priceWrapper .cbvPrice h2 {
        cursor: pointer; }
      @media screen and (max-width: 768px) {
        .step2Wrapper .priceWrapper .cbvPrice {
          margin-bottom: 0px; } }
    .step2Wrapper .priceWrapper [data-am-button] a {
      width: 100%;
      padding: 0; }
    .step2Wrapper .priceWrapper .cbvIncludedInfo {
      font-size: 12px;
      top: 0;
      position: absolute;
      color: #007BCD;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .step2Wrapper .priceWrapper .cbvIncludedInfo {
          color: #ffffff; } }

@-webkit-keyframes backgroundPulse {
  0% {
    background-color: #ffffff; }
  50% {
    background-color: #e5f1fa; }
  100% {
    background-color: #ffffff; } }

@keyframes backgroundPulse {
  0% {
    background-color: #ffffff; }
  50% {
    background-color: #e5f1fa; }
  100% {
    background-color: #ffffff; } }
  .step2Wrapper .tillValWrapper {
    padding: 20px;
    margin: -20px;
    /* The container */
    /* Hide the browser's default checkbox */
    /* Create a custom checkbox */
    /* On mouse-over, add a grey background color */
    /* When the checkbox is checked, add a blue background */
    /* Create the checkmark/indicator (hidden when not checked) */
    /* Show the checkmark when checked */
    /* Style the checkmark/indicator */ }
    .step2Wrapper .tillValWrapper.tillvalWarning {
      -webkit-animation: backgroundPulse 1.5s 2;
              animation: backgroundPulse 1.5s 2;
      -webkit-animation-timing-function: ease;
              animation-timing-function: ease;
      -webkit-animation-delay: 0.6s;
              animation-delay: 0.6s; }
    .step2Wrapper .tillValWrapper .tillvalTitle {
      font-size: 18px; }
    .step2Wrapper .tillValWrapper .checkBoxWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .step2Wrapper .tillValWrapper .checkBoxWrapper .labelWrapper {
        display: flex; }
    .step2Wrapper .tillValWrapper .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 15px;
      color: #007BCD;
      line-height: 20px;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .step2Wrapper .tillValWrapper .tillvalCost {
      color: #161618;
      text-align: right;
      float: right; }
    .step2Wrapper .tillValWrapper .fbi-icon-info {
      color: #007bcd;
      margin-left: 3px;
      cursor: pointer; }
      .step2Wrapper .tillValWrapper .fbi-icon-info:before {
        font-size: 15px; }
    .step2Wrapper .tillValWrapper .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
    .step2Wrapper .tillValWrapper .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: #eee; }
    .step2Wrapper .tillValWrapper .container:hover input ~ .checkmark {
      background-color: #ccc; }
    .step2Wrapper .tillValWrapper .container input:checked ~ .checkmark {
      background-color: #2196F3; }
    .step2Wrapper .tillValWrapper .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .step2Wrapper .tillValWrapper .container input:checked ~ .checkmark:after {
      display: block; }
    .step2Wrapper .tillValWrapper .container .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
  .step2Wrapper .close {
    width: 21px;
    height: 21px;
    top: 0;
    right: 0;
    position: absolute;
    margin: auto;
    padding: 20px;
    z-index: 999;
    opacity: 0.4;
    cursor: pointer; }
  .step2Wrapper .close:before, .step2Wrapper .close:after {
    position: absolute;
    right: 0;
    content: ' ';
    height: 21px;
    width: 2px;
    background-color: #545454;
    top: 8px; }
  .step2Wrapper .close:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .step2Wrapper .close:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .step2Wrapper .infoPopup {
    display: none;
    position: fixed;
    right: 0;
    max-width: 500px;
    z-index: 200;
    min-height: 250px;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.55);
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%); }
    @media screen and (max-width: 480px) {
      .step2Wrapper .infoPopup {
        top: 50%;
        left: 15px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right: 15px;
        max-height: 460px;
        overflow: scroll; } }
    .step2Wrapper .infoPopup.active {
      display: block; }
    .step2Wrapper .infoPopup p span {
      white-space: pre-line; }
  .step2Wrapper .lightboxOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199; }

.cbv-secondary-navigation {
  height: 50px;
  width: 100%;
  background-color: #000;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 50px; }
  @media screen and (max-width: 480px) {
    .cbv-secondary-navigation {
      margin-bottom: 20px;
      padding-left: 20px; } }
  .cbv-secondary-navigation .arrowStepBack {
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .cbv-secondary-navigation a {
    color: #ffffff; }
  .cbv-secondary-navigation p {
    display: inline-block;
    line-height: 50px;
    margin-left: 5px; }
    .cbv-secondary-navigation p strong {
      padding-left: 2px; }

.FrequentQuestionsWrapper .ingress {
  text-align: center; }
  .FrequentQuestionsWrapper .ingress h2 {
    font-size: 38px;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .FrequentQuestionsWrapper .ingress h2 {
        font-size: 28px; } }

.FrequentQuestionsWrapper .cbvInfoBottom {
  padding-top: 40px;
  padding-bottom: 100px; }
  @media screen and (max-width: 768px) {
    .FrequentQuestionsWrapper .cbvInfoBottom {
      padding-bottom: 20px; } }

.FrequentQuestionsWrapper .cbvInfoBottomInnerWrapper {
  max-width: 500px;
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 0 10px; }
  @media screen and (max-width: 768px) {
    .FrequentQuestionsWrapper .cbvInfoBottomInnerWrapper {
      padding: 0; } }
  .FrequentQuestionsWrapper .cbvInfoBottomInnerWrapper [data-am-button] {
    width: 100%;
    margin-top: 40px; }
  .FrequentQuestionsWrapper .cbvInfoBottomInnerWrapper a {
    margin-top: 50px; }

.FrequentQuestionsWrapper .infoGroup {
  margin: 0 auto;
  text-align: left;
  margin-bottom: 50px;
  width: 100%;
  background-color: #d6d6d6;
  text-align: left;
  padding: 0 20px;
  margin-bottom: 10px;
  cursor: pointer; }
  .FrequentQuestionsWrapper .infoGroup .groupTitle {
    font-size: 15px;
    font-family: 'Volvo Novum Medium'; }
  .FrequentQuestionsWrapper .infoGroup .groupText {
    width: 100%;
    max-width: 100%;
    margin: 0;
    max-height: 0;
    transition: 0.4s ease all;
    overflow: hidden;
    text-align: left;
    margin: 0; }
  .FrequentQuestionsWrapper .infoGroup .groupTitle {
    margin-top: 10px;
    position: relative; }
    .FrequentQuestionsWrapper .infoGroup .groupTitle .fbi-icon-angle-down,
    .FrequentQuestionsWrapper .infoGroup .groupTitle .fbi-icon-angle-up {
      display: inline-block;
      position: absolute;
      right: 0;
      opacity: 0.5; }
  .FrequentQuestionsWrapper .infoGroup.toggled .groupText {
    margin-bottom: 20px; }

.FrequentQuestionsWrapper .contacts {
  margin: auto;
  text-align: center;
  padding-top: 50px; }
  @media screen and (max-width: 768px) {
    .FrequentQuestionsWrapper .contacts {
      padding-bottom: 40px; } }
  .FrequentQuestionsWrapper .contacts p {
    margin-bottom: 10px; }
  .FrequentQuestionsWrapper .contacts h2 {
    padding-bottom: 0;
    margin-bottom: 0; }

.noMatchWrapper .noMatchContent {
  max-width: 450px;
  width: 100%;
  margin: auto;
  text-align: center; }

.noMatchWrapper .goBack {
  font-size: 15px;
  color: #007bcd;
  line-height: 21px;
  border-bottom: 0;
  text-decoration: none;
  letter-spacing: -.02em;
  cursor: pointer; }
  .noMatchWrapper .goBack:after {
    display: inline-block;
    font-family: fbi-icons;
    content: "\E870";
    transition: padding .3s ease,margin .3s ease,-webkit-transform .3s ease;
    transition: padding .3s ease,margin .3s ease,transform .3s ease;
    transition: padding .3s ease,margin .3s ease,transform .3s ease,-webkit-transform .3s ease;
    padding-left: 10px;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  .noMatchWrapper .goBack:hover:after {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); }

