.step3FormWrapper {
    width: 50%;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding-bottom: 20px;
    }
    h2 {
        text-align: center;
        padding-bottom: 20px;
    }

    form {
        position: relative;
    }

    .inputError {
        border: 1px solid #ff3d00 !important;
    }

    .formError {
        display: block;
        text-align: center;
        font-family: 'Volvo Novum Regular';
        padding-top: 20px;
        color: #ff3d00;
    }

    .seperator {
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #CEE5F6;
        @media screen and (max-width: 768px) {
            //display: none;
        }
    }

    .formSectionOne {
        background-color: #E5F1FA;
        padding: 0 20px;
        padding-top: 20px;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }

    .formSectionTwo {
        background-color: #e5f1fa;
        padding: 20px 20px 40px 20px;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 20px 10px 20px 10px;
        }
    }

    label {
        padding-bottom: 5px;
        display: block;
        position: relative;
        font-size: 14px;
        color: rgba(0,0,0,.5);
        letter-spacing: .5px;
        font-weight: 400;
    }

    input[type='text'], input[type='email'], textarea, select {
        height: 42px;
        background-color: #fff;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        font-size: 1em;
        font-family: 'Volvo Novum Light';
        margin-bottom: .75em;
        padding: 10px 8px 7px 8px;
        width: 100%;
        box-sizing: border-box;
        transition: border-color;
        appearance: none;
        outline: none;
        @media screen and (max-width: 768px) {
            border: 1px solid #79B7E0;
        }
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #161618;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .formContactHeader {
        display: block;
        width: 100%;
        margin: 0;
        padding: 20px 0;
        font-size: 22px;
    }
    .deadlerHeader {
        padding-top: 0;
        @media screen and (max-width: 768px) {
            padding: 0 10px;
            padding-bottom: 20px;
            padding-top: 10px;
        }
    }
    .formContactWrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        padding-top: 20px;
        .contactForm {
            width: calc(50% - 10px);
            display: inline-block;
            &:nth-of-type(1) {
                @media screen and (min-width: 768px) {
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            p {
                background-color: #fbeaed;
                color: #eb3a47;
                padding: 10px;
                margin-top: -10px;
            }
        }
        @media screen and (max-width: 768px) {
            border: 0;
            //padding: 20px 10px;
            padding-top: 20px;
            padding-bottom: 10px;
        }
    }

    .ssnWrapper {
        width: 100%;
        padding-bottom: 20px;
        padding-top: 0px;
        background-color: #E5F1FA;
        .ssnForm {
            width: 350px;
            margin: auto;
            label {
                color: rgba(0,0,0,1);
                margin-bottom: 10px;
                @media screen and (max-width: 768px) {
                    text-align: center;
                }

                .mobileBreak {
                    display: none;
                    height: 0;
                    @media screen and (max-width: 768px) {
                        display: block;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .ssnError {
                text-align: center;
                color: rgb(238, 86, 97);
                position: relative;
                top: 10px;
            }

            .inputWrapper {
                @media screen and (max-width: 768px) {
                    display: flex;
                    .ssnLabel {
                        display: none;
                    }
                }
                input {
                    text-align: center;
                    margin-bottom: 10px;
                    padding: 0;
                    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                    @media screen and (max-width: 768px) {
                        margin-bottom: 0;
                        margin-right: 5px;
                        max-width: 100%;
                    }
                    &::placeholder {
                        text-align: center;
                        @media screen and (max-width: 480px) {
                            font-size: 12px;
                        }
                    }
                }

                [data-am-button] {
                    position: relative;
                    display: inline-block;
                    background-color: #007bcd;
                    color: #fff !important;
                    border: 1px solid #007bcd;
                    padding-left: 30px;
                    padding-right: 30px;
                    text-align: center;
                    user-select: none;
                    height: 42px;
                    line-height: 42px !important;
                    box-sizing: content-box;
                    font-size: 15px;
                    border: 0;
                    padding: 0;
                    width: 100%;
                    cursor: pointer;
                    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

                    &.loading {
                        background-color: transparent;
                        pointer-events: none;
                    }

                    .blue-button-nofade {
                        &.loading {
                            visibility: hidden;
                            pointer-events: none;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        width: 70px;
                    }
                }

                svg {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
        @media screen and (max-width: 768px) {
            border: 0;
            padding: 20px 10px;
        }
        .formContactHeader {
            @media screen and (max-width: 768px) {
                color: #B4B4B4;
            }
        }
    }

    .personFormWrapper {
        .personform {
            margin-bottom: 5px;
            p {
                background-color: #fbeaed;
                color: #eb3a47;
                padding: 10px;
                margin-top: -10px;
            }
        }
        .positionWrapper {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 20px;
            .personform {
                display: inline-block;
                &:nth-of-type(1) {
                    margin-right: 10px;
                    width: 30%;
                }
                &:nth-of-type(2) {
                    width: calc(70% - 10px)
                }
                @media screen and (max-width: 768px) {
                    display: block;
                    &:nth-of-type(1) {
                        margin-right: 10px;
                        width: 50%;
                    }
                    &:nth-of-type(2) {
                        width: 100%;
                    }
                }
            }
            #custommerPosition {
                @media screen and (max-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .formErrorText {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }

}
