.cbv-secondary-navigation {
    height: 50px;
    width: 100%;
    background-color: #000;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 50px;
    @media screen and (max-width: 480px) {
        margin-bottom: 20px;
        padding-left: 20px;
    }

    .arrowStepBack {
        display: inline-block;
        user-select: none;
    }

    a {
        color: #ffffff;
    }

    p {
        display: inline-block;
        line-height: 50px;
        margin-left: 5px;
        strong {
            padding-left: 2px;
        }
    }
}
