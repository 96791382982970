.startPageWrapper {
    color: #161818;
    overflow: hidden;
    padding-top: 0px;

    .formWrapper {
        padding: 0 20px;
        max-width: 640px;
        margin: 0 auto;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 80px;

        form {
            input {
                margin: 8px 0;
            }
            > div:first-child {
                margin-top: 20px;
            }
        }

        .inputContactError {
            color: #bf2012;
        }

        .inputError {
            border-color: #bf2012;
        }

        .mobileBreak {
            display: none;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
    }

    .startInnerWrapper {
        width: 100%;
        height: 100%;
        max-width: 1250px;
        margin: auto;
        padding: 0;
        padding-bottom: 50px;
        padding-top: 50px;
        text-align: center;
        @media screen and (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 50px;
        }

        .anchorLink {
            display: inline-block;
            padding-top: 20px;
            color: #007BCD;
            cursor: pointer;
            .fbi-icon-angle-down {
                padding-left: 10px;
            }
        }

        .cbvIntro {
            padding-bottom: 50px;
            h3, h1, h2, p {
                margin: 0;
            }
            h1, h2 {
                font-family: 'Volvo Novum Medium';
            }
            h1 {
                font-size: 44px;
                line-height: 50px;
                @media screen and (max-width: 768px) {
                    font-size: 31px;
                    line-height: 40px;
                    padding: 0;
                }
            }
            h2 {
                font-size: 24px;
                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }
            h3 {
                margin-bottom: 6px;
            }
            p {
                padding-top: 30px;
                padding-bottom: 30px;
                font-size: 18px;
            }
        }

        .tableIndicator {
            position: relative;
            &:before {
                display: none;
                @media screen and (max-width: 768px) {
                    padding: 0 10px;
                }
            }
            &.hide {
                &:before {
                    opacity: 0;
                }
            }
        }

        .tableTitle {
            padding-top: 40px;
            padding-bottom: 40px;
            position: relative;
            @media screen and (max-width: 768px) {
                padding-top: 30px;
                padding-bottom: 20px;
            }
        }

        .dropDownThWrapper {
            display: none;
            padding: 0 !important;
            white-space: inherit !important;
            overflow: inherit !important;
            word-break: inherit !important;
            @media screen and (max-width: 768px) {
                display: block;
            }
            .Dropdown-root {
                margin-left: 5px;
                font-size: 12px;
                line-height: 27px;

                .Dropdown-control {
                    padding: 5px !important;
                }
                .Dropdown-arrow {
                    right: 7px;
                    top: 17px;
                }
            }
        }

        .cbvTableWrapper {
            max-width: 1000px;
            margin: auto;
            padding-bottom: 0px;
            width: 100%;
            @media screen and (max-width: 480px) {
                overflow-x: scroll;
                padding-bottom: 50px;
                margin-bottom: 0px;
                margin-left: -20px;
                margin-right: -20px;
                padding: 0 20px;
                width: auto;
            }
            .cbvTable {
                border-spacing: 0;
                margin: auto;
                @media screen and (max-width: 768px) {
                    overflow: scroll;
                    width: 100%;
                    table-layout: fixed;
                }
                @media screen and (max-width: 480px) {
                    padding: 0 10px;
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                tbody {
                    @media screen and (max-width: 768px) {
                        overflow: scroll;
                    }
                }
                td {
                    padding: 10px 5px;
                    overflow: hidden;
                    word-break: normal;
                    text-align: center;
                    vertical-align: middle;
                    &.fbi-icon-cancel {
                        color: #818181;
                    }
                }

                th {
                    padding: 10px 5px;
                    overflow: hidden;
                    word-break: normal;
                    max-width: 160px;
                    font-family: 'Volvo Novum Regular';
                    @media screen and (max-width: 768px) {
                        padding: 10px;
                        font-size: 12px;
                        white-space: nowrap;
                    }
                    &.cbvth {
                        width: 100px;
                    }
                    &:nth-child(2) {
                        border-top: 1px solid #9e9e9e;
                        border-left: 1px solid #9e9e9e;
                        border-right: 1px solid #9e9e9e;
                        @media screen and (max-width: 768px) {
                            //border: 0;
                        }
                    }
                    .mobileWidth {
                        @media screen and (max-width: 768px) {
                            width: 25%;
                        }
                    }
                    &.mobileHide {
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }
                }


                tr td {
                    text-align: left;
                    text-align: center;
                    vertical-align: middle;
                    &:nth-child(n+2) {
                        text-align: center;
                    }
                    &:nth-child(n+3) {
                        opacity: 1;
                        transition: 0.3s ease opacity;

                        &.animating {
                            opacity: 0;
                        }
                    }
                    &:nth-child(n+4) {
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }
                    &:nth-child(1) {
                        text-align: left;
                        border-bottom: 1px solid #d2d2d2;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        width: 15%;
                        @media screen and (max-width: 768px) {
                            min-width: 170px;
                        }
                    }
                    &:nth-child(2) {
                        border-left: 1px solid #9e9e9e;
                        border-right: 1px solid #9e9e9e;
                        border-bottom: 0;
                        border-top: 0;
                        padding: 0 50px;
                        width: 1%;
                        @media screen and (max-width: 768px) {
                            //border: 0;
                            //border-bottom: 1px solid #d2d2d2;
                            white-space: nowrap;
                            padding: 0 30px;
                        }
                    }
                    &:nth-child(3) {
                        border-bottom: 1px solid #d2d2d2;
                        width: 10%;
                    }
                    &:nth-child(4) {
                        border-bottom: 1px solid #d2d2d2;
                        width: 10%;
                    }
                }

                tr {
                    &:last-child {
                        td {
                            border: 0;
                            &:nth-child(2) {
                                border-bottom: 1px solid #9e9e9e;
                                border-left: 1px solid #9e9e9e;
                                border-right: 1px solid #9e9e9e;
                                @media screen and (max-width: 768px) {
                                    //border: 0;
                                }
                            }
                        }
                    }
                    .fbi-icon-checkmark {
                        color: #007BCD;
                        font-size: 18px;
                        &:before {
                            font-weight: 600;
                        }
                    }
                }
            }

        }

    }


    .cbvStartBanner {
        width: 100%;
        padding-bottom: 30px;
        position: relative;

        .bannerImg {
            width: 100%;
            height: 740px;
            position: relative;
            background-image: url('../../assets/img/cbvStartBanner.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @media screen and (max-width: 480px) {
                height: 300px;
            }
        }

        .textWrapper {
            width: 100%;
            max-width: 490px;
            position: absolute;
            left: 130px;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;
            background: rgba(255,255,255, 0.5);
            padding: 20px;
            border-radius: 2px;
            @media screen and (max-width: 768px) {
                left: 0;
                background: none;
            }
            @media screen and (max-width: 480px) {
                position: relative;
                display: block;
                transform: none;
                padding-top: 30px;
                padding-right: 20px;
                padding-left: 20px;
            }
            h3 {
                margin: 0;
            }
            h2 {
                margin-top: 10px;
                margin-bottom: 25px;
                font-size: 28px;
                max-width: 400px;
                @media screen and (max-width: 480px) {
                    line-height: 34px;
                }
            }
            a {
                font-family: 'Volvo Novum Medium';
                color: #181616;
            }
        }

    }

    .cbvInfoBottomIntro {
        text-align: center;
        display: block;
        width: 100%;
        margin: auto;
        padding-top: 0px;
        padding-bottom: 50px;
        @media screen and (max-width: 768px) {
            padding: 0 20px;
            margin-bottom: 30px;
        }

        @media screen and (min-width: 768px) {
            background-color: #ffffff;
            padding: 50px 0;
            padding-top: 0;
        }

        h2 {
            display: block;
            width: 100%;
            text-align: center;
            padding-bottom: 10px;
        }

        .cbvInfoText {
            max-width: 650px;
            @media screen and (min-width: 768px) {
                margin-bottom: 10px;
                padding-top: 30px;
            }
        }

        .stepWrapper {
            width: 100%;
            width: 300px;
            display: flex;
            height: 100px;
            align-items: center;
            margin: auto;
            margin-bottom: 50px;
            margin-top: -20px;
            @media screen and (min-width: 768px) {
                margin-bottom: 40px;
                width: 460px;
            }

            .circle {
                border-radius: 50%;
                width: 30px;
                height: 26px;
                padding: 4px;
                border: 2px solid #666;
                text-align: center;
                font-size: 12px;
                line-height: 13px;
                @media screen and (max-width: 768px) {
                    height: 24px;
                    line-height: 12px;
                }
                p {
                    position: absolute;
                    margin-left: -30px;
                    text-align: center;
                    margin-top: 12px;
                    font-size: 15px;
                    line-height: normal;

                }

                &.circle1 {
                    p {
                        margin-left: -15px;
                    }
                }
                &.circle2 {
                    p {
                        margin-left: -30px;
                    }
                }
                &.circle3 {
                    p {
                        margin-left: -20px;
                    }
                }

            }

          .line {
            width: 50%;
            border-top: 2px solid #666666;
          }

        }

    }

    .cbvInfoBottom {
        padding-top: 0px;
        padding-bottom: 30px;
        margin-bottom: 50px;
        background-color: #f6f6f6;
        padding-bottom: 60px;
        @media screen and (max-width: 768px) {
            padding-top: 30px;
            padding-bottom: 50px;
        }

        h2 {
            text-align: center;
            padding-bottom: 30px;
            @media screen and (max-width: 768px) {
                display: block;
                text-align: center;
                padding-bottom: 30px;
            }
            &.cbvInfoBottomHeader {
                @media screen and (min-width: 768px) {
                    padding-top: 60px;
                }
            }
        }

        .cbvInfoBottomInnerWrapper {
            width: 100%;
            height: 100%;
            max-width: 1250px;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            padding: 0 15px;
            [data-am-button] {
                width: 100%;
                display: inline-block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
            @media screen and (max-width: 768px) {
                padding: 0 10px;
                [data-am-button] {
                    width: 100%;
                    margin-top: 40px;
                }
            }

            a {
                margin-top: 50px;
            }

        }

        .infoGroup {
            width: 33%;
            margin: 15px 0;
            text-align: left;
            @media screen and (max-width: 900px) {
                width: 50%;
            }
            .groupTitle {
                font-size: 22px;
                margin-top: 10px;
                position: relative;
                .fbi-icon-angle-down, .fbi-icon-angle-up {
                    display: none;
                }
                @media screen and (max-width: 768px) {
                    .fbi-icon-angle-down, .fbi-icon-angle-up {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        opacity: 0.5;
                    }
                }
            }
            .groupText {
                max-width: 330px;
                text-align: left;
                margin: 0;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                background-color: #d6d6d6;
                text-align: left;
                padding: 0 20px;
                margin-bottom: 10px;
                margin-top: 0;
                .groupTitle {
                    font-size: 15px;
                    font-family: 'Volvo Novum Medium';
                }
                .groupText {
                    margin: 0;
                    max-height: 0;
                    transition: 0.4s ease all;
                    overflow: hidden;
                }
            }
            &.toggled {
                .groupText {
                    margin-bottom: 0px;
                }
                @media screen and (max-width: 768px) {
                    .groupText {
                        //max-height: 200px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    [data-am-button] {
        margin: 0 20px;
        a {
            width: 220px;
        }
    }

    .blue-button-nofade {
        @media screen and (max-width: 768px) {
            width: 100% !important;
            margin: auto !important;
            padding: 0 !important;
        }
    }

    .mobileBreak {
        display: none;
        @media screen and (max-width: 480px) {
            display: block;
            font-size: 0;
            line-height: 0;
        }
    }

    .cbvContacts {
        margin: auto;
        padding: 0 20px 50px 20px;
        text-align: center;
        h2 {
            padding-bottom: 10px;
        }
        p {
            margin: 0 auto 10px;
        }
        .links {
            padding-top: 0px;
            a {
                display: block;
                max-width: 300px;
                margin: auto;
                &:last-of-type {
                    padding-top: 10px;
                }
            }
        }
        .contacts {
            @media screen and (max-width: 768px) {
                padding-top: 50px;
            }
        }
    }

}
