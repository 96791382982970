.contactMeContainer {
    width: 100%;
    position: relative;
    display: block;
    padding: 10px 15px;
    padding-top: 60px;
    margin: 15px;
    margin-bottom: 45px;
    background-color: #393939;
    color: #ffffff;
    text-align: center;
    @media screen and (max-width: 900px) {
        padding-top: 30px;
        margin: 0;
        margin-bottom: 20px;
    }

    .contactMeInnerWrapper {
        position: relative;
        opacity: 1;
        transition: opacity 0.4s ease;
        &.hideForm {
            opacity: 0;
        }
    }

    .confirmMessage {
        display: block;
        position: absolute;
        opacity: 1;
        top: 45%;
        left: 0;
        right: 0;
        transition: opacity 0.6s ease;
        &.hideMessage {
            pointer-events: none;
            opacity: 0;
        }
        h2 {
            margin-bottom: 0;
        }
    }

    .headerContainer {
        h2 {
            display: inline-block;
            position: relative;
            margin-bottom: 3px;
            &:before {
                content: '\e876';
                font-family: 'fbi-icons';
                font-style: normal;
                font-weight: 400;
                font-size: 100px;
                display: inline-block;
                text-decoration: inherit;
                width: auto;
                margin-right: .2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                margin-left: .2em;

                position: absolute;
                bottom: -50px;
                left: -180px;

                @media screen and (max-width: 900px) {
                    content: none;
                    display: none;
                }
            }
        }
        p {
            text-align: left;
            max-width: 395px;
            @media screen and (max-width: 900px) {
                text-align: center;
            }
        }
    }

    form {
        width: 100%;
        max-width: 850px;
        margin: auto;
        padding: 20px 0;
        padding-top: 50px;
        @media screen and (max-width: 900px) {
            max-width: 550px;
            padding-top: 20px;
        }
        [data-am-input] {
            width: calc(33% - 15px);
            margin: 0;
            display: inline-block;
            @media screen and (max-width: 900px) {
                width: 100%;
                display: block;
                padding: 0;
            }
            &:nth-child(2) {
                margin: 0 15px;
                @media screen and (max-width: 900px) {
                    margin: 15px 0;
                }
            }
            input {
                margin-bottom: 0;
            }
        }

        [data-am-textarea] {
            margin: 0 11px;
            margin-top: 20px;
            margin-bottom: 12px;
            @media screen and (max-width: 900px) {
                margin-left: 0;
                margin-right: 0;
            }
            textarea {
                height: 100px;
            }
        }

        input[type="submit"] {
            max-width: 400px;
            text-align: center;
            margin: auto;
            height: 45px;
            line-height: 45px;
            margin-top: 35px;
        }
    }

}
