.carSummaryWrapper {
    display: flex;
    flex-wrap: wrap;
    width: calc(50% - 40px);
    order: 2;
    justify-content: flex-end;
    padding-right: 50px;
    @media screen and (max-width: 768px) {
        width: 100%;
        order: 2;
        padding-bottom: 40px;
        padding-right: 0px;
    }
    .carWrapper {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .colorsWrapper {
        padding-bottom: 40px;
        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
        .colorsTitle {
            margin-bottom: 15px;
        }

        .colorContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .selectedColorWrapper {
                .color {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 3px;
                    width: 30px;
                    height: 30px;
                }
                .selectedColor {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 0;
                    margin-left: 10px;
                }
            }
            .colorPrice {
                margin: 0;
            }
        }
    }

    .poseWrapper {
        overflow: hidden;
        @media screen and (max-width: 768px) {
            padding: 0 10px;
        }
    }
    .optionsWrapper {
        width: 100%;
        max-width: 400px;
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: none;
        }
        .optionsTitle {
            font-size: 18px;
        }
    }

    .priceWrapper {
        border-top: 2px solid #007BCD;
        padding-top: 20px;
        margin-top: 30px;
        text-align: center;
        .cbvPrice {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                .optionsTitle {
                    display: none;
                }
                p {
                    margin: 0;
                }
            }

            .totalPriceWrapper {
                text-align: left;
                .totalPrice {
                    position: relative;
                    font-size: 21px !important;
                    margin-bottom: 2px;
                    @media screen and (max-width: 768px) {
                        margin-bottom: 3px !important;
                    }
                }
                .totalSub {
                    font-size: 12px;
                }
            }
        }
        [data-am-button] {
            @media screen and (max-width: 768px) {
                height: 52px !important;

                .blue-button-nofade {
                    height: 52px !important;
                    font-size: 13px;
                }
            }
            &:hover {
                p {
                    &:after {
                        transform: translateX(4px);
                    }
                }
            }
            p {
                &:after {
                    content: '\e870';
                    transition: transform ease .4s;
                    font-family: fbi-icons;
                    font-style: normal;
                    font-weight: 400;
                    speak: none;
                    display: inline-block;
                    text-decoration: inherit;
                    width: auto;
                    margin-right: .2em;
                    text-align: center;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1em;
                    margin-left: 10px;
                }
            }
        }
    }

    .formErrorText {
        @media screen and (max-width: 786px) {
            display: none;
        }
    }

    // * --- tillval --- * //

    .tillValWrapper {
        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .optionWrapper {
                cursor: pointer;
                span.fbi-icon-cancel {
                    display: inline-block;
                    font-size: 10px;
                    color: #007BCD;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    vertical-align: middle;
                }
                .option {
                    display: inline-block;
                    color: #007BCD;
                    margin-bottom: 5px;
                    vertical-align: middle;
                }
            }

            .optionPrice {
                margin: 0;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 5px;
            }
        }
    }

    .speech-bubble {
        margin-top: 30px;
        background: #E5F1FA;
        padding: 20px;
        text-align: center;
        color: #183080;
        position:relative;
        border-radius: 15px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-bottom-color: #E5F1FA;
            border-top: 0;
            border-left: 0;
            margin-left: 0px;
            margin-top: -10px;
            transform: rotate(135deg);
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }


    .textWrapper {
        text-align: center;
        position: relative;
        @media screen and (max-width: 768px) {
            margin-left: -10px;
            margin-right: -10px;
            padding: 10px;
        }
        .subtitle {
            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }
        h2 {
            @media screen and (max-width: 768px) {
                font-family: 'Volvo Novum Medium';
                font-size: 18px;
                margin-bottom: 5px;
            }
        }
        .mobile-back {
            display: none;
            background-color: #007BCD;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 15%;
            @media screen and (max-width: 768px) {
                display: inline-block;

                .arrowBack {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99;
                }
            }
            a {
                color: #ffffff;
            }
        }
        a {
            color: #ffffff;
        }
    }

    .lightboxOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 199;
    }

    .cbvIncludedInfo {
        font-size: 12px;
        top: -10px;
        right: -30px;
        position: absolute;
        color: #007BCD;
        cursor: pointer;
        padding: 10px;
        @media screen and (max-width: 768px) {
            color: #ffffff;
            top: inherit;
            right: inherit;
            padding: 0;
        }
    }


}
