.step2Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        //overflow-x fix
        margin-left: -10px;
        margin-right: -10px;
    }

    .carWrapper {
        width: 50%;
        max-width: 800px;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        .textWrapper {
            text-align: center;
            position: relative;
            @media screen and (max-width: 768px) {
                text-align: center;
                padding: 10px;
                padding-bottom: 0;
            }
            .subtitle {
                @media screen and (max-width: 768px) {
                    margin: 0;
                }
            }
            h2 {
                @media screen and (max-width: 768px) {
                    font-family: 'Volvo Novum Medium';
                    font-size: 18px;
                    margin: auto;
                    margin-bottom: 5px;
                }
            }
            .mobile-back {
                display: none;
                background-color: #007BCD;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 15%;
                @media screen and (max-width: 768px) {
                    display: inline-block;

                    .arrowBack {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 99;
                    }
                }
                a {
                    color: #ffffff;
                }
            }
            a {
                color: #ffffff;
            }
        }
    }

    .extraInfoPopup {
        opacity: 0;
        position: fixed;
        right: 0;
        max-width: 500px;
        z-index: 200;
        min-height: 250px;
        padding: 20px;
        background-color: #f5f5f5;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.55);
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
        @media screen and (max-width: 480px) {
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            right: 15px;
            max-height: 460px;
            overflow: scroll;
        }
        p {
            span {
                white-space: pre-line;
            }
        }
    }

    .speechBubbleModal {
        width: 100%;
        position: absolute;
        display: block;
        overflow: hidden;
        height: 100%;
        @media screen and (max-width: 768px) {
            position: relative;
        }
    }
    .speech-bubble {
        width: 100%;
        position: absolute;
        margin-top: 30px;
        background: #E5F1FA;
        padding: 20px;
        text-align: center;
        color: #183080;
        border-radius: 15px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-bottom-color: #E5F1FA;
            border-top: 0;
            border-left: 0;
            margin-left: 0px;
            margin-top: -10px;
            transform: rotate(135deg);
        }
        @media screen and (max-width: 768px) {
            position: relative;
        }
    }

    .TextModal2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .TextModal1, .TextModal2 {
        @media screen and (max-width: 768px) {
            font-size: 13px;
        }
    }

    [data-am-button] {
        .blue-button-nofade {
            width: 100%;
            position: relative;
            display: inline-block;
            background-color: #007bcd;
            color: #fff!important;
            border: 1px solid #007bcd;
            transition: background-color .3s ease,color .3s ease;
            text-align: center;
            user-select: none;
            height: 55px;
            line-height: 55px!important;
            box-sizing: content-box;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                height: 52px !important;
                line-height: 52px !important;
            }
            &:hover {
                p {
                    &:after {
                        transform: translateX(4px);
                    }
                }
            }
            p {
                &:after {
                    content: '\e870';
                    transition: transform ease .4s;
                    font-family: fbi-icons;
                    font-style: normal;
                    font-weight: 400;
                    speak: none;
                    display: inline-block;
                    text-decoration: inherit;
                    width: auto;
                    margin-right: .2em;
                    text-align: center;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1em;
                    margin-left: 10px;
                }
            }
        }
    }

    .techData {
        max-width: 500px;
        padding-bottom: 100px;
        text-align: center;

      @media screen and (min-width: 768px) {
          padding-bottom: 40px;
          padding-top: 40px;
      }
    }

    .colorsWrapper {
        padding-bottom: 20px;
        @media screen and (max-width: 768px) {
            padding-bottom: 10px;
        }
        .colorsTitle {
            margin-bottom: 15px;
            font-size: 18px;
        }
        .colorWrapper, .color {
            display: inline-block;
            margin: 0 3px;
            width: 30px;
            height: 30px;
            cursor: pointer;

            &:first-of-type {
                margin-left: 0px;
            }
            &.selected {
                border: 2px solid #007BCD;
            }

            img {
                &[data-selected="true"] {
                    border: 2px solid #007BCD;
                }
                &#selected {
                    border: 2px solid #007BCD;
                }
                &.selected {
                    border: 2px solid #007BCD;
                }
            }

        }
        img {
            width: 30px;
            height: 30px;
            &:hover {
                border: 2px solid #007BCD;
            }
        }
    }

    .poseWrapper {
        overflow: hidden;
    }
    .optionsWrapper {
        position: relative;
        width: 50%;
        max-width: 320px;
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: none;
            padding: 0 20px;
        }

        .extraTillval {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: #007bcd;
            cursor: pointer;
        }
    }

    .priceWrapper {
        border-top: 2px solid #CCCCCC;
        padding-top: 20px;
        margin-top: 30px;
        text-align: center;

        @media screen and (max-width: 768px) {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 0;
            display: flex;
            background-color: #073653;
            padding: 10px;
            color: #fff;
            border: 0;
            margin-top: 0;
            z-index: 10;

            .cbvPrice {
                display: flex;
                flex-wrap: wrap;
                width: 50%;
                margin-bottom: 0;
                text-align: left;
                @media screen and (max-width: 768px) {
                    flex-flow: column;
                    p {
                        font-size: 12px;
                    }
                }
                h2 {
                    margin-bottom: 2px;
                    @media screen and (max-width: 768px) {
                        margin-bottom: 4px;
                    }
                }
            }
            [data-am-button] {
                width: 50%;
                a {
                    height: 50px;
                    line-height: 50px!important;
                }
                @media screen and (max-width: 768px) {
                    height: 52px;
                    line-height: 52px;
                }
            }
        }

        .cbvPrice {
            margin-bottom: 20px;
            p {
                margin: 0;
            }
            h2 {
                cursor: pointer;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 0px;
            }
        }

        [data-am-button] {
            a {
                width: 100%;
                padding: 0;
            }
        }

        .cbvIncludedInfo {
            font-size: 12px;
            top: 0;
            position: absolute;
            color: #007BCD;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                color: #ffffff;
            }
        }
    }

    // * --- tillval checkboxes --- * //

    @keyframes backgroundPulse {
      0% {
        background-color: #ffffff;
      }
      50% {
        background-color: #e5f1fa;
      }
      100% {
        background-color: #ffffff;
      }
    }

    .tillValWrapper {
        padding: 20px;
        margin: -20px;
        &.tillvalWarning {
            animation: backgroundPulse 1.5s 2;
            animation-timing-function: ease;
            animation-delay: 0.6s;
        }
        .tillvalTitle {
            font-size: 18px;
        }

        .checkBoxWrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .labelWrapper {
                display: flex;
            }
        }
        /* The container */
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 15px;
          color: #007BCD;
          line-height: 20px;
          user-select: none;
        }

        .tillvalCost {
            color: #161618;
            text-align: right;
            float: right;
        }
        .fbi-icon-info {
            color: #007bcd;
            margin-left: 3px;
            cursor: pointer;
            &:before {
                font-size: 15px;
            }
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 22px;
          width: 22px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196F3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
    }

    .close {
        width: 21px;
        height: 21px;
        top: 0;
        right: 0;
        position: absolute;
        margin: auto;
        padding: 20px;
        z-index: 999;
        opacity: 0.4;
        cursor: pointer;
    }
    .close:before, .close:after {
        position: absolute;
        right: 0;
        content: ' ';
        height: 21px;
        width: 2px;
        background-color: #545454;
        top: 8px;
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }

    /* -- Popup -- */

    .infoPopup {
        display: none;
        position: fixed;
        right: 0;
        max-width: 500px;
        z-index: 200;
        min-height: 250px;
        padding: 20px;
        background-color: #f5f5f5;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.55);
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
        @media screen and (max-width: 480px) {
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            right: 15px;
            max-height: 460px;
            overflow: scroll;
        }
        &.active {
            display: block;
        }
        p {
            span {
                white-space: pre-line;
            }
        }
    }

    .lightboxOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 199;
    }

}
