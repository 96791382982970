.cbvCarWrapper {
    position: relative;
    width: calc(50% - 30px);
    margin-bottom: 30px;
    background-color: #f5f5f5;
    padding: 50px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: center;
    //align-items: flex-end;
    margin-right: 15px;
    margin-left: 15px;
    //padding-top: 30px;
    padding-bottom: 150px;
    padding-top: 50px;
    z-index: 0;

    .cbvCarTitleWrapper {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        @media screen and (max-width: 768px) {
            position: relative;
            left: initial;
            transform: none;
        }
        .carName, .carTitle {
            text-align: center;
        }
        .carName {
            max-width: 500px;
            margin: auto;
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        flex-flow: column;
        margin-bottom: 20px;
        width: 100%;
        padding: 15px;
        margin-right: 0;
        margin-left: 0;
        &:nth-child(2n) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .cbvCarPriceWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 20px;

        @media screen and (max-width: 1000px) {
            flex-flow: column;
        }
        @media screen and (max-width: 768px) {
            flex-flow: nowrap;
        }
        @media screen and (min-width: 768px) {
            position: absolute;
            bottom: 30px;
            left: 0;
            padding: 0 50px;
        }
        .cbvPrice {
            width: 50%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            h2 {
                margin-bottom: 3px;
            }
            p {
                @media screen and (max-width: 480px) {
                    font-size: 12px;
                }
            }
        }
        [data-am-button] {
            width: 50%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            a {
                width: 100%;
                padding: 0;
            }
        }
    }
    .popular {
        position: absolute;
        top: 15px;
        left: -19px;
        background-color: #007BCD;
        width: 150px;
        padding: 12px 0px;
        text-align: center;
        &:before {
            content: "";
            width: 0px;
            height: 0;
            border-top: 24px solid transparent;
            border-right: 19px solid #014877;
            border-bottom: 12px solid transparent;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }
        p {
            color: #fff;
            line-height: 0;
            margin-bottom: 0;
            font-family: 'Volvo Novum Medium';
            padding-left: 10px;
            margin-left: -15px;
        }
    }
}
