@import url('https://vcs.fbinhouse.se/volvo-style-guide/assets/toolkit/styles/fbi-volvo-style-guide.css');

body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    overflow-y:scroll;
    &.disableScroll {
        overflow: hidden !important;
    }
}

#fbiPixels {
    line-height: 0;
    font-size: 0;
    height: 0;
    margin: 0;
    padding: 0;
}

.zoid-outlet {
    display: none;
}

#root {
    width: 100%;
}

.fbi-content {
    width: 100%;
}

.outerAppWrapper {
    min-height: 100vh;
}


.cbvCarPage-section {
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;
    padding-bottom: 100px;
    @media screen and (max-width: 768px) {
        position: relative;
        padding: 0 10px;
        margin-bottom: 50px;
    }
}

.pageLoader {
    height: 1px;
    font-size: 0;
    line-height: 0;
}
