@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.SummarySliderWrapper {
    display: inline-block;
    margin-left: 0;
    width: 100%;
    padding: 0;
    @media screen and (max-width: 768px) {
        padding-bottom: 0;
        padding-top: 0;
        margin-left: 0;
    }

    .sliderWrapper {
        @media screen and (max-width: 768px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    img {
        width: 100%;
        @media screen and (max-width: 768px) {
            width: 90%;
            margin: auto;
        }
    }

    .slick-slider {
         outline: none !important;
        .slick-arrow {
            z-index: 99;

            &.slick-prev {
                left: -35px;
                &:before {
                    content: '\e86f';
                    font-family: fbi-icons;
                    font-size: 24px;
                    opacity: 0.4;
                    color: #000;
                }
                @media screen and (max-width: 768px) {
                    left: -5px;
                }
                @media screen and (max-width: 480px) {
                    left: 0px !important;
                }
            }
            &.slick-next {
                right: -20px;
                text-align: right;
                &:before {
                    content: '\e870';
                    font-family: fbi-icons;
                    font-size: 24px;
                    opacity: 0.4;
                    color: #000;
                }
                @media screen and (max-width: 768px) {
                    right: 5px;
                }
                @media screen and (max-width: 480px) {
                    right: 0px !important;
                }
            }
        }

        .slick-dots {
            bottom: -20px;
            @media screen and (max-width: 768px) {
                bottom: -15px;
            }
            li {
                margin: -2px;
                width: 15px;
                button {
                    padding: 0;
                    &:before {
                        width: 2px;
                        height: 2px;
                        display: inline-block;
                        box-sizing: content-box;
                        border: 1px solid #000;
                        position: relative;
                        border-radius: 50%;
                        color: transparent;
                        background-color: black;
                        opacity: 0.4;
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            display: inline-block;
                            box-sizing: content-box;
                            border: 1px solid #007BCD;
                            position: relative;
                            border-radius: 50%;
                            color: transparent;
                            background-color: #007BCD;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .slick-slide {
            div {
                outline: none !important;
            }
        }
    }
}
