.noMatchWrapper {
    .noMatchContent {
        max-width: 450px;
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .goBack {
        font-size: 15px;
        color: #007bcd;
        line-height: 21px;
        border-bottom: 0;
        text-decoration: none;
        letter-spacing: -.02em;
        cursor: pointer;
        &:after {
            display: inline-block;
            font-family: fbi-icons;
            content: "\e870";
            transition: padding .3s ease,margin .3s ease,transform .3s ease;
            padding-left: 10px;
            transform: translateX(0px);
        }
        &:hover:after {
            transform: translateX(4px);
        }
    }
}
