.retailersWrapper {
    position: relative;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
        padding: 0;
        margin-top: 20px;
    }
    span {
        position: absolute;
        right: 10px;
        top: 35px;
    }
    .dealerSelect {
        margin: 0;
    }

    p {
        background-color: #fbeaed;
        color: #eb3a47;
        padding: 10px;
    }
}
