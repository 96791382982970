.FrequentQuestionsWrapper {
    .ingress {
        text-align: center;

        h2 {
            font-size: 38px;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                font-size: 28px;
            }
        }
    }

    .cbvInfoBottom {
        padding-top: 40px;
        padding-bottom: 100px;
        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
    }
    .cbvInfoBottomInnerWrapper {
        max-width: 500px;
        margin-top: 50px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        padding: 0 10px;
        @media screen and (max-width: 768px) {
            padding: 0;
        }

        [data-am-button] {
            width: 100%;
            margin-top: 40px;
        }

        a {
            margin-top: 50px;
        }
    }

    .infoGroup {
        margin: 0 auto;
        text-align: left;
        margin-bottom: 50px;

        width: 100%;
        background-color: #d6d6d6;
        text-align: left;
        padding: 0 20px;
        margin-bottom: 10px;
        cursor: pointer;

        .groupTitle {
            font-size: 15px;
            font-family: 'Volvo Novum Medium';
        }

        .groupText {
            width: 100%;
            max-width: 100%;
            margin: 0;
            max-height: 0;
            transition: 0.4s ease all;
            overflow: hidden;
            text-align: left;
            margin: 0;
        }

        .groupTitle {
            margin-top: 10px;
            position: relative;

            .fbi-icon-angle-down,
            .fbi-icon-angle-up {
                display: inline-block;
                position: absolute;
                right: 0;
                opacity: 0.5;
            }
        }

        &.toggled {
            .groupText {
                margin-bottom: 20px;
            }
        }
    }
    [data-am-button] {
        //text-align: center !important;
    }

    .contacts {
        margin: auto;
        text-align: center;
        padding-top: 50px;
        @media screen and (max-width: 768px) {
            padding-bottom: 40px;
        }
        p {
            margin-bottom: 10px;
        }
        h2 {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}
