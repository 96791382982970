.carSummaryWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
    order: 2;
    justify-content: flex-end;
    padding-right: 50px;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding-bottom: 40px;
        padding-right: 0px;
    }
    .carWrapper {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }


    }

    .colorsWrapper {
        padding-bottom: 40px;
        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
        .colorsTitle {
            margin-bottom: 15px;
        }

        .colorContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .selectedColorWrapper {
                .color {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 3px;
                    width: 30px;
                    height: 30px;
                }
                .selectedColor {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 0;
                    margin-left: 10px;
                }
            }
            .colorPrice {
                margin: 0;
            }
        }
    }

    .poseWrapper {
        overflow: hidden;
        @media screen and (max-width: 768px) {
            padding: 0 10px;
        }
    }
    .optionsOrderWrapper {
        width: 100%;
        max-width: 400px;
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: none;
        }
        .optionsTitle {
            font-size: 18px;
            margin-left: 0px;
        }
    }

    .priceWrapper {
        border-top: 2px solid #007BCD;
        padding-top: 20px;
        margin-top: 30px;
        text-align: center;
        .cbvPrice {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;

            h2 {
                cursor: pointer;
            }
            @media screen and (max-width: 768px) {
                .optionsTitle {
                    display: none;
                }
            }
            p {
                margin: 0;
            }
            .totalPriceWrapper {
                text-align: left;
                .totalPrice {
                    font-size: 22px;
                    margin-bottom: 2px;
                }
                .totalSub {
                    font-size: 12px;
                }
            }
        }
        [data-am-button] {
            position: relative;
            display: inline-block;
            background-color: #007bcd;
            color: #fff!important;
            border: 1px solid #007bcd;
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
            user-select: none;
            height: 55px;
            line-height: 55px!important;
            box-sizing: content-box;
            font-size: 15px;
            border: 0;
            padding: 0;
            width: 100%;
            cursor: pointer;
            span {
                transition: transform .3s ease,color .3s ease;
                position: absolute;
                transform: translateX(5px);
            }
            &:hover {
                span {
                    transform: translateX(8px);
                }
            }
        }

        @media screen and (max-width: 768px) {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 0;
            display: flex;
            background-color: #073653;
            padding: 10px;
            color: #fff;
            border: 0;
            margin-top: 0;
            z-index: 99;

            .cbvPrice {
                display: flex;
                flex-wrap: wrap;
                width: 50%;
                margin-bottom: 0;
                text-align: left;
                .totalPrice {
                    margin-bottom: 2px;
                }
            }
            [data-am-button] {
                width: 50%;
                a {
                    height: 50px;
                    line-height: 50px!important;
                }
            }
        }
    }

    // * --- tillval --- * //

    .tillValWrapper {
        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .optionWrapper {
                cursor: pointer;
                span.fbi-icon-cancel {
                    display: inline-block;
                    font-size: 10px;
                    color: #007BCD;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    vertical-align: middle;
                }
                .option {
                    display: inline-block;
                    color: #007BCD;
                    margin-bottom: 5px;
                    vertical-align: middle;
                }
            }

            .optionPrice {
                margin: 0;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 5px;
            }
        }
    }

    .speech-bubble {
        margin-top: 30px;
        background: #E5F1FA;
        padding: 20px;
        text-align: center;
        color: #183080;
        position:relative;
        border-radius: 15px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-bottom-color: #E5F1FA;
            border-top: 0;
            border-left: 0;
            margin-left: 0px;
            margin-top: -10px;
            transform: rotate(135deg);
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }


    .textWrapper {
        text-align: center;
        position: relative;
        @media screen and (max-width: 768px) {
            margin-left: -10px;
            margin-right: -10px;
            padding: 10px;
        }
        .subtitle {
            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }
        h2 {
            @media screen and (max-width: 768px) {
                font-family: 'Volvo Novum Medium';
                font-size: 18px;
                margin-bottom: 5px;
            }
        }
        .mobile-back {
            display: none;
            background-color: #007BCD;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 15%;
            @media screen and (max-width: 768px) {
                display: inline-block;

                .arrowBack {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99;
                }
            }
            a {
                color: #ffffff;
            }
        }
        a {
            color: #ffffff;
        }
    }

    .orderComplete {
        //position: fixed;
        //left: 50%;
        //transform: translateX(-50%);
        top: 200px;
        width: 100vw;
        height: 100%;
        padding-top: 0px;
        background: white;
        z-index: 0;
        text-align: center;
        margin-right: -50px;
        p {
            max-width: 400px;
        }

        @media screen and (max-width: 768px) {
            top: 0;
            margin: 0;
            min-width: 100vw;
            transform: none;
            left: 0;
            padding-top: 0px;
            margin-right: -10px;
        }

        .orderWrapper {
            padding-top: 10px;;
            margin: auto;
            max-width: none;
            margin-bottom: 10px;
            .innerWrapper {
                max-width: 500px;
                margin: auto;
                padding: 40px;
                padding-bottom: 10px;
                background-color: #f5f5f5;
                @media screen and (max-width: 768px) {
                    padding: 20px;
                    padding-top: 40px;
                }
                .textWrapper {
                    h2 {
                        font-size: 22px;
                        line-height: 28px;
                    }
                    @media screen and (max-width: 768px) {
                        background-color: transparent;
                        color: #161618;
                        margin: 0;
                        padding: 0;
                        h2, p {
                            margin: 0;
                            white-space: normal;
                            max-width: none;
                            text-align: center;
                        }
                        h2 {
                            font-size: 20px;
                            font-family: 'Volvo Novum Light';
                            padding-bottom: 10px;
                        }
                    }
                }

                .orderImg {
                    width: 100%;
                    max-width: 400px;
                }

                .sliderWrapper {
                    padding-bottom: 20px;
                    padding-top: 10px;
                    @media screen and (max-width: 768px) {
                        padding: 10px 0 !important;
                    }
                }
                .optionsTitle {
                    text-align: left;
                }
                .optionWrapper {
                    .option {
                        color: #161618;
                    }
                }
                .subtitle {
                    margin-bottom: 0;
                }
                .priceWrapper {
                    @media screen and (max-width: 768px) {
                        border-top: 1px solid #161618;
                        margin-top: 10px;
                        background-color: transparent;
                        color: #161618;
                        position: relative;
                    }
                }
            }
        }
    }

}
