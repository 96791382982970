    .includedPopup {
        position: fixed;
        right: 0;
        max-width: 500px;
        z-index: 200;
        background-color: #f5f5f5;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.55);
        left: 50%;
        top: 45%;
        -webkit-transform: translate(-50%, -40%);
        transform: translate(-50%, -40%);
        h2 {
            font-size: 20px;
            font-family: 'Volvo Novum Medium';
            margin-bottom: 0;
        }

        .innerWrapper {
            overflow-y: scroll;
            height: 60%;
            max-height: 600px;
            padding: 20px;
        }
        @media screen and (max-width: 768px) {
            width: 96%;
        }
    }

    .close {
        width: 21px;
        height: 21px;
        top: 2px;
        right: 10px !important;
        position: absolute;
        margin: auto;
        padding: 20px;
        z-index: 999;
        opacity: 0.4;
        cursor: pointer;
        @media screen and (min-width: 786px) {
            right: 0px !important;
        }
    }
    .close:before, .close:after {
        position: absolute;
        left: 20px;
        content: ' ';
        height: 25px;
        width: 2px;
        background-color: #545454;
        top: 8px;
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }
