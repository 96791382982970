.hero-section {
    background: url("../../../assets/img/cbv-hero-desktop.jpg") no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background-position: 50% 20%;
    background-repeat: no-repeat;
    position: relative;
    text-align: left;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.15);
    }
    @media screen and (min-width: 1600px) {
        background-position: 50% 35% !important;
    }
    @media screen and (max-width: 1600px) {
        height: 60vh;
        background-position: 50% 30% !important;
    }
    @media screen and (min-width: 1800px) {
        height: 70vh !important;
    }

    @media screen and (max-width: 768px) {
        height: 82vh;
        background-position: 50% 50% !important;
        background: url("../../../assets/img/cbv-hero-mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .heroWrapper {
        width: 100%;
        max-width: 1600px;
        position: relative;
        height: 100%;
        margin: auto;
    }

    .hero-section-text {
        color: #ffffff;
        position: absolute;
        left: 100px;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
        @media screen and (max-width: 1400px) {
            left: 80px;
        }
        @media screen and (max-width: 768px) {
            top: 50%;
            left: 0;
        }

        ul {
            margin-left: 0;
            margin-bottom: 30px;
            padding-left: 20px;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
                margin-top: 5px;
            }
        }
        @media screen and (max-width: 768px) {
            position: relative;
            right: 0;
            height: 100%;
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        h1 {
            margin-top: 5px;
            font-family: 'Volvo Novum Medium';
            @media screen and (max-width: 480px) {
                margin-top: 0;
                margin-bottom: 0px;
            }
        }

        h3 {
            margin-bottom: 0;
        }

        p {
            max-width: 400px;
            @media screen and (max-width: 768px) {
                max-width: none;
            }

            strong {
                display: block;
                padding-top: 20px;
            }
        }

        [data-am-button] {
            a {
                width: 240px;
                @media screen and (max-width: 768px) {
                    display: block;
                    margin: auto;
                }
            }
            @media screen and (max-width: 768px) {
                bottom: 52px;
                position: absolute;
                right: 0;
                left: 0;
                margin: 0 20px !important;
            }
            @media screen and (min-width: 767px) {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        .heroPriceWrapper {
            @media screen and (max-width: 768px) {
                position: absolute;
                bottom: 119px;
                left: 0;
                right: 0;
                color: #ffffff;
                margin: 0 20px;
            }

            p {
                font-family: 'Volvo Novum Regular';
                font-size: 13px;
                margin: 0;
                strong {
                    padding: 0;
                }
            }
            .heroPriceTitle {
                font-size: 15px;
                font-family: 'Volvo Novum Medium';
            }
            .heroPriceText {
                font-size: 20px;
                font-family: 'Volvo Novum Medium';
            }
        }
    }

    .anchorLink {
        max-width: 140px !important;
        margin: 0;
        margin-top: 10px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
            position: absolute;
            bottom: 10px;
            max-width: 100% !important;
            text-align: center;
            left: 0;
            right: 0;
        }
        span {
            margin-left: 5px;
        }
    }
}
