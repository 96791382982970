
.stepOuterWrapper {
    margin-left: -10px;
    margin-right: -10px;

    .mobile-back {
        display: none;
        position: relative;
        background-color: #073653;
        height: 100%;
        width: 100%;
        padding: 22px 0;

        .backTitle {
            color: #fff;
            text-align: center;
            margin-bottom: 0;
            font-size: 18px;
            font-family: 'Volvo Novum Medium';
        }
        @media screen and (max-width: 768px) {
            display: block;

            .iconWrapper {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #007BCD;
                width: 15%;
                height: 100%;
                .arrowBack {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99;
                }
            }
        }
        a {
            color: #ffffff;
        }
    }

    .stepInnerWrapper {
        margin: 0 10px;
        .header {
            margin: auto;
            padding-bottom: 20px;
            text-align: center;
            @media screen and (max-width: 768px) {
                padding: 20px 0;
                padding-top: 0px;
                h3 {
                    margin-bottom: 0;
                }
                h1 {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
