.equipmentListWrapper {
    padding-top: 50px;
    width: 100%;
    max-width: 1200px;
    @media screen and (max-width: 768px) {
        padding-bottom: 20px;
        padding-top: 30px;
    }
    h2 {
        text-align: center;
        padding-bottom: 50px;
        @media screen and (max-width: 768px) {
            padding-bottom: 10px;
        }
    }
    .equipmentList {
        display: block;
        width: 100%;
        position: relative;

        .listWrapper {
            column-count: 3;
            column-rule: 1px solid lightgrey;
            column-gap: 150px;
            padding: 0 20px;
            @media screen and (max-width: 768px) {
                padding-left: 30px;
                column-count: 1;
            }
            p {
                &.additional {
                    color: #007BCD;
                    text-decoration: underline;
                    cursor: pointer;
                }
                &:before {
                    content: "";
                      border: 2px #000 solid !important;
                      border-radius: 50%;
                      margin-top: 10px;
                      margin-left: -10px;
                      position: absolute;
                }

            }

        }
    }

    .featureBoxWrapper {
        position: fixed;
        top: 40%;
        transform: translateY(-40%);
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        max-height: 340px;
        z-index: 99;
        padding: 10px 20px 20px;
        width: 100%;
        max-width: 400px;
        @media screen and (max-width: 480px) {
            position: fixed;
            top: 20%;
            left: 15px;
            right: 15px;
            z-index: 99;
            width: auto;
        }

    .featureTextBox {
        overflow-y: auto;
        position: absolute;
        background-color: #f5f5f5;
        width: 100%;
        height: 350px;
        left: 0;
        top: 0;
        padding: 20px;
        box-shadow: 0px 0px 11px 1px rgba(161,161,161,0.58);
        .featureTextHeader {
            font-family: 'Volvo Novum Medium';
            margin-top: 0px;
        }
        p {
            &:before {
                border: 0 !important;
            }
        }
    }
    &:after {
        display: none;
        background-color: #f5f5f5;
        box-shadow: -1px 1px 2px 0 rgba(161, 161, 161, 0.25);
        content: "\A0";
        height: 15px;
        left: -10px;
        position: relative;
        top: 20px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 15px;
        top: -7px;
        left: -27px;
    }

    .close {
        width: 21px;
        height: 21px;
        top: 0;
        right: 0;
        position: absolute;
        margin: auto;
        z-index: 999;
        top: 0;
        opacity: 0.4;
        cursor: pointer;
    }
    .close:before, .close:after {
        position: absolute;
        right: 0;
        content: ' ';
        height: 21px;
        width: 2px;
        background-color: #545454;
    }
        .close:before {
            transform: rotate(45deg);
        }
        .close:after {
            transform: rotate(-45deg);
        }
    }

    .lightbox {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        //background: rgba(0,0,0,1);
        background-color: transparent;
        z-index: 10;
    }
}
